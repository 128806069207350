
import { PreferenceDefaultServiceService } from './../preference-default-service/preference-default-service.service';
/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { GoogleMapsApiLoaderService } from '../google-maps-api-loader/google-maps-api-loader.service';
declare let google: any;
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  private _map: BehaviorSubject<google.maps.Map> = new BehaviorSubject<google.maps.Map>(null);
  private _currentMapRef: google.maps.Map;
  private facilityFocusZoomLevel = 11; // lower = more zoomed out
  private _mapLoadedStatic = false;
  private _mapHasInitialZoom: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentLocation: google.maps.LatLng;

  constructor(private mapsApiLoader: GoogleMapsApiLoaderService, private prefDefaultServ: PreferenceDefaultServiceService) { }
  get map() {
    return this._map.asObservable();
  }



  get currentMapRef() {
    return this._currentMapRef;
  }

  public initialMapZoom(): Promise<void> {
    return new Promise((res) => {
      const sub = this._mapHasInitialZoom.asObservable().subscribe((isTrue) => {
        if (isTrue) {
          if (sub) {
            sub.unsubscribe();
          }
          res();
        }
      });
    });
  }

  getMapRefLoadPromise(): Promise<google.maps.Map> {
    return new Promise((resolve) => {
      if (this._mapLoadedStatic === true && this._currentMapRef) {
        return resolve(this._currentMapRef);
      }
      const sub = this.map.subscribe((map) => {
        if (map) {
          sub.unsubscribe();
          return resolve(map);
        }
      });
    });
  }

  createMap(el: Element,
    latLng: google.maps.LatLng,
    config: any): Promise<google.maps.Map> {
    return new Promise((resolve, reject) => {

      // Make sure the google maps API has been loaded before trying to create the map
      const sub = this.mapsApiLoader.loaded.subscribe(async (loaded) => {
        if (loaded) {
          this._mapLoadedStatic = true;
          const zm = this.prefDefaultServ.defaultMapZoom;
          const cn = await this.prefDefaultServ.getDefaultMapLocation();
          const _config = Object.assign({
            zoom: zm,
            center: cn,
            zoomControl: true,
            clickableIcons: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            draggable: true,
            scrollwheel: true
          }, config);
          if (latLng !== null) {
            _config.center = latLng;
          }
          this.currentLocation = latLng;
          const newMap = new google.maps.Map(el, _config);
          this._map.next(newMap);
          this._currentMapRef = newMap;
          this.createTrafficLayer(newMap);
          resolve(newMap);
          if (sub) {
            sub.unsubscribe();
          }
        }
      });
    });
  }

  private createTrafficLayer(mp: google.maps.Map) {
    const trafficLayer = new google.maps.TrafficLayer();
    trafficLayer.setMap(mp);
  }


  async changeMapLocation(location: google.maps.LatLng) {
    await this.getMapRefLoadPromise();
    this._currentMapRef.panTo(location);
    this._currentMapRef.setZoom(this.facilityFocusZoomLevel);
    this._mapHasInitialZoom.next(true);
    this.currentLocation = location;
  }
}

