const stateBounds = [
  {
  "name": "California",
  "maxLat": 42.0126,
  "minLat": 35.6166,
  "maxLong": -118.5418,
  "minLong": -124.4359,
  "limit": 650,
  "count": 0
  },
  {
  "name": "Utah",
  "maxLat": 42.0044,
  "minLat": 36.9935,
  "maxLong": -109.0441,
  "minLong": -114.0456,
  "limit": 200,
  "count": 0
  },
  {
    "name": "Nevada",
    "maxLat": 42.021,
    "minLat": 35.6258,
    "maxLong": -114.0319,
    "minLong": -119.9865,
    "limit": 200,
    "count": 0
  },
  {
  "name": "Wyoming",
  "maxLat": 44.99,
  "minLat": 40.98,
  "maxLong": -104.05,
  "minLong": -111.03,
  "limit": 100,
  "count": 0
  },
  {
  "name": "Arizona",
  "maxLat": 37.0155,
  "minLat": 31.2877,
  "maxLong": -109.0358,
  "minLong": -114.4274,
  "limit": 100,
  "count": 0
  },
  {
  "name": "Alaska",
  "maxLat": 71.15,
  "minLat": 56.93,
  "maxLong": -140.88,
  "minLong": -168.04,
  "limit": 5,
  "count": 0
  },
  {
  "name": "Montana",
  "maxLat": 49.0,
  "minLat": 44.93,
  "maxLong": -104.1,
  "minLong": -115.94,
  "limit": 100,
  "count": 0
  },
]


export function checkInStateBounds(location) {
  const lat = location.latitude;
  const lng = location.longitude
  for(const s of stateBounds){ 
    if(s['count'] < s['limit'] && lat <= s['maxLat'] && lat >= s['minLat'] && lng <= s['maxLong'] && lng >= s['minLong']){
      s['count'] += 1
      return true
    }
  }
  return false;
}

