import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';
import { SettingsPanelAnimationService } from 'src/services/settings-panel-animation/settings-panel-animation.service';
import { PreferenceDefaultServiceService } from './../../services/preference-default-service/preference-default-service.service';
import { FacilityService } from '../../services/facility-service/facility.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';



@Component({
  selector: 'app-facility-filter',
  templateUrl: './facility-filter.component.html',
  styleUrls: ['./facility-filter.component.scss']
})

export class FacilityFilterComponent implements OnInit {
  @ViewChild('facInput') facInput: ElementRef;
  facilities = new FormControl();
  facilityList: Array<object>;
  storedFacilityList: Array<object>;

  facilityHash = {};
  selectedFacility: object; // has fac name and ID
  displayDrop = false;
  facilitySelected = false;
  searchInput = null;

  constructor(private facilityS: FacilityService,
    private prefDefaultsServ: PreferenceDefaultServiceService,
    private settingsServ: SettingsPanelAnimationService) {
  }
  ngOnInit() {
    this.getFacilities();
    this.settingsServ.settingsPanelStateListener.subscribe((curState) => {
      this.handleFacPanelClose(curState);
    });
  }

  handleFacPanelClose(curState: SettingsPanelState) {
    if (curState !== SettingsPanelState.Facility) {
      this.displayDrop = false;
      this.checkSelectedFacilityDisplay();
      if (this.facilitySelected === true) {
        this.clearSearch();
      }
    }
  }

  clearSearch() {
    this.facInput.nativeElement.value = '';
    this.facilityList = this.storedFacilityList;
  }

  async getFacilities() {
    const availableFacIds = await this.facilityS.availableFacilityIds();
    const availableFacs = await this.facilityS.availableFacilities();
    availableFacs.forEach(el => {
      this.facilityHash[el['facilityId']] = el;
    });
    let selectedFacID = null;
    if (availableFacs) {
      this.facilityList = availableFacs;
      this.storedFacilityList = availableFacs;
      const defaultFacilityExists = this.prefDefaultsServ.defaultFacility
        && availableFacIds.indexOf(this.prefDefaultsServ.defaultFacility) !== -1 ? true : false;
      if (availableFacs && defaultFacilityExists) {
        selectedFacID = this.prefDefaultsServ.defaultFacility;
      }
      this.facilityS.setFacilityCookieLoadedObs(defaultFacilityExists);
      this.facilityUpdate(selectedFacID);
    }
  }

  // Gets called when a new facility is selected
  async facilityUpdate(selectedFacID) {
    this.selectedFacility = this.facilityHash[selectedFacID];
    this.checkSelectedFacilityDisplay();
    await this.timeOutPromise(30);
    this.facilityS.updateSelectedFacility(selectedFacID);
  }

  checkSelectedFacilityDisplay() {
    this.facilitySelected = this.selectedFacility ? true : false;
  }

  inputClick() {
    this.displayDrop = true;
  }

  facOptClick(e) {
    this.settingsServ.updateSettingsPanelState(SettingsPanelState.Closed);
    this.facilityUpdate(e.target.dataset.value);
  }

  facTextClick() {
    this.facilitySelected = false;
    this.displayDrop = true;
    this.facInput.nativeElement.focus();
  }

  inputChange(searchTxt) {
    this.facilityList = this.filterFacilityList(searchTxt);
  }

  filterFacilityList(searchTxt): Array<object> {
    return this.storedFacilityList.filter(fac => this.facSearch(searchTxt, fac));
  }

  facSearch(searchTxt, facObj): boolean {
    if (this.checkMatch(searchTxt, facObj.facilityId)) {
      return true;
    }
    if (this.checkMatch(searchTxt, facObj.name)) {
      return true;
    }
    return false;
  }

  checkMatch(searchTxt, checkF) {
    const facTxtArr = checkF.split(' ');
    const sArr = searchTxt.toLowerCase().split('');
    for (let x = 0; x < facTxtArr.length; x++) {
      const fArr = facTxtArr[x].toLowerCase().split('');
      let isMatch = true;
      for (let i = 0; i < sArr.length; i++) {
        if (i > fArr.length) {
          break;
        }
        if (sArr[i] !== fArr[i]) {
          isMatch = false;
          break;
        }
      }
      if (isMatch === true) { return true; }

    }
    return false;

  }


  timeOutPromise(timeMs: number): Promise<void> {
    return new Promise((resolve) => {
      const wait = setTimeout(() => {
        clearTimeout(wait);
        resolve();
      }, timeMs);
    });
  }

}
