import { GenericIcon } from './generic-icon.model';

export class FacilityIcon extends GenericIcon {
  private _facilityIconSize = 28;
  private _zIndex = 30;
  iconFileFleet = `${this.iconBaseDir}/facility-icon-fleet.svg`;
  iconFileSP = `${this.iconBaseDir}/facility-icon-SP.svg`;
  facilityName: string;
  facilityAddr1: string;
  facilityAddr2: string;
  facTypeLowerCase: string;
  facilityType: string; // "Service Provider" || "Club Fleet"
  currentLocation: google.maps.LatLng;

  constructor(currMap: google.maps.Map,
    facilityName: string,
    facilityAddr1: string,
    facilityAddr2: string,
    facType: string,
    facTypeLowerCase: string,
    currLoc: google.maps.LatLng) {
    super(currMap);
    this.facilityName = facilityName;
    this.facilityAddr1 = facilityAddr1;
    this.facilityAddr2 = facilityAddr2;
    this.facilityType = facType;
    this.facTypeLowerCase = facTypeLowerCase;
    this.currentLocation = currLoc;
    this.createMarker();
  }

  createMarker() {
    const newIcon = {
      url: this.facilityType === 'Club Fleet' ? this.iconFileFleet : this.iconFileSP,
      scaledSize: new google.maps.Size(this._facilityIconSize, this._facilityIconSize),
      zIndex: this._zIndex
    };
    const markerOptions = { position: this.currentLocation };
    markerOptions['icon'] = newIcon;
    this._marker = new google.maps.Marker(markerOptions);
    this.setMarkerInfoWindow();
    this.updateInfoWindow(this.generateFacilityInfoStr());
    // fix for info window flicker
    this._currentInfoWindow.set('pixelOffset', new google.maps.Size(0, -3));
  }

  generateFacilityInfoStr(): string {
    return `
    <div style="text-align:left; padding: 5px;">
      <div style="text-align: left;
      color: navy;
      font-size: 14px;
      margin-bottom: 1px;
      font-weight: 500;">${this.facilityName}</div>
      <div>${this.facilityAddr2}</div>
      <div >Facility Type: <span >${this.facilityType}</span></div>
    </div>
    `;
  }
  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }
}

