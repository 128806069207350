import { LoadingAnimationService } from './../../services/loading-animation/loading-animation.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { state, transition, animate, trigger, style } from '@angular/animations';

@Component({
  selector: 'app-loader-icon',
  templateUrl: './loader-icon.component.html',
  styleUrls: ['./loader-icon.component.scss'],
  animations: [
    trigger('showHide', [

      state('show', style({ opacity: '1', display: 'flex' })),
      state('hide', style({ opacity: '0', display: 'none' })),
      transition('show => hide', [
        animate('500ms ease-in')
      ]),
      transition('hide=> show', [
        animate('0ms')
      ])
    ])
  ]
})
export class LoaderIconComponent implements OnInit {
  @ViewChild('myDiv') myDiv: ElementRef;
  isDefaultShowing = false;
  hideDefaultLoader = false;


  constructor(private loadingAni: LoadingAnimationService) { }

  ngOnInit() {
    this.loadingAni.loaderListener.subscribe((showState) => {
      if (showState !== this.isDefaultShowing) {
        this.isDefaultShowing = showState;
      }

    });

  }

}
