import { CrvShapeFileGrid } from './../../models/map-marker/crvShapeFile.model';
import { IconLayersService } from './../icon-layers/icon-layers.service';
import { CountyGridsService } from './../county-grids/county-grids.service';
import { FacilityService } from './../facility-service/facility.service';
import { SQLDataService } from '../SQL-Data/SQL-Data';
import { PredictionGrid, GridTypes } from './../../models/map-marker/prediction-grid.model';
import { WaitLocation } from './../../models/map-marker/waitLocation-icon.model';
import { IconLayerTypes } from 'src/services/icon-layers/icon-layers.service';
import { FacilityIcon } from './../../models/map-marker/facility-icon.model';
import { BranchIcon } from './../../models/map-marker/branch-icon.model';
import { Injectable, ElementRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { GoogleMapService } from 'src/services/google-map/google-map.service';
import { TruckIcon } from 'src/models/map-marker/truck-icon.model';
import { ServiceIcon } from 'src/models/map-marker/serviceCall-icon.model';
import { PredictionIcon } from 'src/models/map-marker/prediction-icon.model';
import { AirQualityIcon } from 'src/models/map-marker/airQuality-icon.model';
import { ActiveFireLayer } from 'src/models/map-marker/activeFire-layer.model';
import { TruckStatus } from 'src/models/map-marker/icon-status/truck-status';
import { Observable, BehaviorSubject } from 'rxjs';
import { ServiceStatus } from 'src/models/map-marker/icon-status/service-status';
import { HttpRequestsService } from '../httpRequests/http-requests.service';
import {checkInStateBounds} from 'src/app/helper-funcs/stateLatLngs'
declare let google: any;

@Injectable({
  providedIn: 'root'
})
export class MapMarkerService {

  // _allDefaultIcons structure: trucks: {}, serviceCalls: {}
  private _allDefaultIcons = new Map<string, any>();

  // NOTE: _allViewLayerIcons needs to use the same string values of the IconLayerTypes enum in the icon layers service
  private _allViewLayerIcons = {
    'BRANCH': [],
    'FLEET': [],
    'SERVICE_PROVIDER': [],
    'WAIT_LOCATIONS': [],
    'SHOW_OV': [],
    'CLOSED_CALLS': [],
    'BOUNDRIES': [],
    'FIRES': [],
    'AIR_QUALITY': [],
    'COUNTIES': [],
    'SHAPEFILE_TOW': [],
    'SHAPEFILE_LIGHT': [],
    'SHAPEFILE_BATTERY': []
  };
  // NOTE: Cached wait locations are put into allViewLayerIcons -> WAIT_LOCATIONS by CheckCreateWaitLocationIcons()
  // so they can be used by the showViewLayerIconsByType() hideViewLayerIconsByType() functions
  private _cachedWaitLocations = {};
  private _allPredictionNumberMarkers = { 'TOW': [], 'SERVICE': [] };
  private _truckAddIconObs: BehaviorSubject<TruckIcon> = new BehaviorSubject<TruckIcon>(null);
  private _allServiceIconObs: BehaviorSubject<Array<ServiceIcon>> = new BehaviorSubject<Array<ServiceIcon>>([]);
  private _serviceIconDelObs: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private _PredictionGrids = [];
  private currentSelectedFacility = null;
  private previouslySelectedFacility = null;
  private maxFacIconLatLngDist = .7;

  constructor(private mapService: GoogleMapService,
    private bigQueryServ: SQLDataService,
    private facilityServ: FacilityService,
    private countyServ: CountyGridsService,
    private icnLayerServ: IconLayersService,
    private mapServ: GoogleMapService,
    private httpReq: HttpRequestsService
  ) {
    this.generateNewIconMap();
    this.facilityServ.currentSelectedFacilityIDObs.subscribe((facId) => {
      this.handleNewCurrentFacilityUpdate(facId);
      this.pruneOVfilter(facId);
      this.pruneClosedCallsFilter(facId);
    });
    this.createFireLayer();
    this.preloadCounties();
  }

  public get allTruckIconsAsHash(): Array<TruckIcon> {
    return this._allDefaultIcons['trucks'];
  }

  public get allServiceIconsAsHash(): Array<ServiceIcon> {
    return this._allDefaultIcons['serviceCalls'];
  }

  public get allServiceIconObs(): Observable<Array<ServiceIcon>> {
    return this._allServiceIconObs.asObservable();
  }


  public get truckIconAddObs(): Observable<TruckIcon> {
    return this._truckAddIconObs.asObservable();
  }

  getTruckIconById(truckId: string): TruckIcon {
    return this._allDefaultIcons.get('trucks')[truckId];
  }

  get allTruckIcons(): Array<TruckIcon> {
    return Object.values(this._allDefaultIcons.get('trucks'));
  }

  getServiceIconById(callId: string): ServiceIcon {
    return this._allDefaultIcons.get('serviceCalls')[callId];
  }

  public get allServiceIcons(): Array<ServiceIcon> {
    return Object.values(this._allDefaultIcons.get('serviceCalls'));
  }

  getAirQualityIconById(id: string){
    return this._allViewLayerIcons[IconLayerTypes.AirQuality].find(el => el.id === id);;
  }

  removeAirQualityIconById(id: string){
    let index = this._allViewLayerIcons[IconLayerTypes.AirQuality].findIndex(el => el.id === id);
    if(index !== -1){
      this._allViewLayerIcons[IconLayerTypes.AirQuality][index].setVisability(false);
      this._allViewLayerIcons[IconLayerTypes.AirQuality].splice(index, 1);
    }
    
  }

  addAirQualityIcon(icon: AirQualityIcon){
    this._allViewLayerIcons[IconLayerTypes.AirQuality].push(icon);
  }

  // Notifies with service call ID string when a service Icon has been deleted
  public get serviceIconDelObs(): Observable<string> {
    return this._serviceIconDelObs.asObservable();
  }


  // Removes OV trucks when facilities are selected/deselected
  private pruneOVfilter(facId: string) {
    this._allViewLayerIcons[IconLayerTypes.ShowOV] =
      this._allViewLayerIcons[IconLayerTypes.ShowOV].filter(trk => trk.facility_Id === facId);
  }


  private pruneClosedCallsFilter(facID: string) {
    this._allViewLayerIcons[IconLayerTypes.ClosedCalls] =
      this._allViewLayerIcons[IconLayerTypes.ClosedCalls].filter(trk => trk.facility_Id === facID);
  }

  private async createFireLayer() {
    if (this._allViewLayerIcons[IconLayerTypes.Fires].length === 0) {
      const currMap = await this.mapService.getMapRefLoadPromise();
      this._allViewLayerIcons[IconLayerTypes.Fires].push(new ActiveFireLayer(currMap, this.httpReq));
    }
  }

  private async preloadCounties() {
    const currMap = await this.mapService.getMapRefLoadPromise();
    this._allViewLayerIcons[IconLayerTypes.Counties] = this.countyServ.loadCountyGrids(currMap);
  }

  public addShowOVicon(TrkIcn: TruckIcon) {
    if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ShowOV)) {
      TrkIcn.setVisability(true);
    } else {
      TrkIcn.setVisability(false);
    }
    if (this.showOVIconExists(TrkIcn) === false) {
      this._allViewLayerIcons[IconLayerTypes.ShowOV].push(TrkIcn);
    }
  }

  public addShowClosedCallIcon(CallIcn: ServiceIcon) {
    if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ClosedCalls)) {
      CallIcn.setVisability(true);
    } else {
      CallIcn.setVisability(false);
    }
    if (this.showClosedCallIconExists(CallIcn) === false) {
      this._allViewLayerIcons[IconLayerTypes.ClosedCalls].push(CallIcn);
    }
  }

  public removeShowOVicon(TrkIcn: TruckIcon) {
    // Use filter to set a new array
    TrkIcn.setVisability(true);
    this._allViewLayerIcons[IconLayerTypes.ShowOV] = this._allViewLayerIcons[IconLayerTypes.ShowOV]
      .filter(el => el.full_truck_id !== TrkIcn.full_truck_id);
  }

  public removeShowClosedCallIcon(CallIcn: ServiceIcon) {
    // Use filter to set a new array
    CallIcn.setVisability(true);
    this._allViewLayerIcons[IconLayerTypes.ClosedCalls] = this._allViewLayerIcons[IconLayerTypes.ClosedCalls]
      .filter(el => el.serviceCallId === CallIcn.serviceCallId);
  }

  private showOVIconExists(TrkIcn: TruckIcon): boolean {
    return this._allViewLayerIcons[IconLayerTypes.ShowOV].find(el => el.full_truck_id === TrkIcn.full_truck_id) !== undefined;
  }

  private showClosedCallIconExists(CallIcn: ServiceIcon): boolean {
    return this._allViewLayerIcons[IconLayerTypes.ClosedCalls].find(el => el.serviceCallId === CallIcn.serviceCallId) !== undefined;
  }

  public removeAllCrvShapeFiles() {
    this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileBattery, false);
    this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileTow, false);
    this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileLight, false);
    this._allViewLayerIcons[IconLayerTypes.ShapeFileBattery] = [];
    this._allViewLayerIcons[IconLayerTypes.ShapeFileTow] = [];
    this._allViewLayerIcons[IconLayerTypes.ShapeFileLight] = [];
  }

  public showSelectedCrvShapeFiles() {
    if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ShapeFileBattery)) {
      this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileBattery, true);
    }
    if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ShapeFileTow)) {
      this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileTow, true);

    }
    if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ShapeFileLight)) {
      this.blockingToggleIconLayerVisability(IconLayerTypes.ShapeFileLight, true);
    }
  }

  public async addAllCrvShapeFiles(shapes: Object) {
    const currMap = await this.mapService.getMapRefLoadPromise();
    if (shapes) {
      if (shapes[IconLayerTypes.ShapeFileBattery]) {
        shapes[IconLayerTypes.ShapeFileBattery].forEach((sh) => {
          const newShape = new CrvShapeFileGrid(currMap, sh);
          this._allViewLayerIcons[IconLayerTypes.ShapeFileBattery].push(newShape);
        });
      }
      if (shapes[IconLayerTypes.ShapeFileTow]) {
        shapes[IconLayerTypes.ShapeFileTow].forEach((sh) => {
          const newShape = new CrvShapeFileGrid(currMap, sh);
          this._allViewLayerIcons[IconLayerTypes.ShapeFileTow].push(newShape);
        });
      }
      if (shapes[IconLayerTypes.ShapeFileLight]) {
        shapes[IconLayerTypes.ShapeFileLight].forEach((sh) => {
          const newShape = new CrvShapeFileGrid(currMap, sh);
          this._allViewLayerIcons[IconLayerTypes.ShapeFileLight].push(newShape);
        });
      }
    }
  }

  // NOTE: Shape files are handled by the shape file service on fac change because of the functions needed to parse the results
  async handleNewCurrentFacilityUpdate(facId: string) {
    // Remove current wait locations and boundries so they can be updated
    this.blockingToggleIconLayerVisability(IconLayerTypes.WaitLocations, false);
    this.blockingToggleIconLayerVisability(IconLayerTypes.ServiceFacility, false);

    if (facId) {
      this.currentSelectedFacility = facId;
      await this.bigQueryServ.SQLdataInitialLoadPromise();
      // update our internal array of wait location markers for the new facility
      await this.CheckCreateWaitLocationIcons();

      // after we have the marker info, if waitlocations option is selected, show waitlocations
      if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.WaitLocations)) {
        this.blockingToggleIconLayerVisability(IconLayerTypes.WaitLocations, true);
      }
      if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.ServiceFacility)) {
        this.blockingToggleIconLayerVisability(IconLayerTypes.ServiceFacility, true);
      }

    } else {
      this.currentSelectedFacility = null;
    }
    this.previouslySelectedFacility = this.currentSelectedFacility;
    return Promise.resolve();
  }

  generateNewIconMap() {
    this._allDefaultIcons.set('trucks', {});
    this._allDefaultIcons.set('serviceCalls', {});
    this._allServiceIconObs.next([]);
  }


  private deleteTruckIconById(truckId: string) {
    delete this._allDefaultIcons.get('trucks')[truckId];
  }

  // Note this should only be called by the actual icon delete function, this is just a helper,
  private deleteServiceIconById(id: string) {
    delete this._allDefaultIcons.get('serviceCalls')[id];
    this._serviceIconDelObs.next(id);
  }

  removeAllTruckIcons() {
    Object.keys(this._allDefaultIcons.get('trucks')).forEach((truckId) => {
      this.removeTruckIcon(truckId, 'DELETE');
    });
  }

  removeAllServiceCallIcons() {
    Object.keys(this._allDefaultIcons.get('serviceCalls')).forEach((callId) => {
      this.removeServiceIcon(callId, 'DELETE');
    });
  }

  addTruckIcon(truckId: string, icon: TruckIcon) {
    if (truckId in this._allDefaultIcons.get('trucks') !== true) {
      this._allDefaultIcons.get('trucks')[truckId] = icon;
      this._truckAddIconObs.next(icon);
    } else {
      console.log('Error: Trying to add a truck icon that already exists');
    }
  }

  addServiceIcon(serviceId: string, icon: ServiceIcon) {
    if (serviceId in this._allDefaultIcons.get('serviceCalls') !== true) {
      this._allDefaultIcons.get('serviceCalls')[serviceId] = icon;
      this._allServiceIconObs.next(this.allServiceIcons);
    } else {
      console.log('Error: Trying to add a service call icon that already exists');
    }
  }


  public async CheckCreateWaitLocationIcons() {
    const currMap = await this.mapService.getMapRefLoadPromise();
    if (this.currentSelectedFacility === null || this.currentSelectedFacility === this.previouslySelectedFacility) {
      return Promise.resolve();
    }
    if (currMap === null) {
      await this.mapService.getMapRefLoadPromise();
    }
    let waitIcons = [];

    // Clear the existing wait location icons
    this._allViewLayerIcons[IconLayerTypes.WaitLocations] = [];

    // Check if we already have the icons in chache, if not create and add them
    if (this.currentSelectedFacility in this._cachedWaitLocations) {
      waitIcons = this._cachedWaitLocations[this.currentSelectedFacility];
    } else {
      const result = await this.bigQueryServ.getWaitLocationsByFacId(this.currentSelectedFacility);
      result.forEach((WL) => {
        const waitIcon = new WaitLocation(
          currMap,
          new google.maps.LatLng(WL.lat, WL.lng),
          WL.address,
          WL.facID,
          WL.name);
        waitIcons.push(waitIcon);
      });
      this._cachedWaitLocations[this.currentSelectedFacility] = waitIcons;
    }

    this._allViewLayerIcons[IconLayerTypes.WaitLocations] = waitIcons;
    return Promise.resolve();
  }

  public async createNewFacilityIcon(
    name: string,
    addr1: string,
    addr2: string,
    facType: string,
    facTypeLC: string,
    location: google.maps.LatLng
  ): Promise<void> {
    const currMap = await this.mapService.getMapRefLoadPromise();
    if (currMap === null) {
      await this.mapService.getMapRefLoadPromise();
    }

    const facIcon = new FacilityIcon(currMap, name, addr1, addr2, facType, facTypeLC, location);
    if (facType === 'Club Fleet') {
      this._allViewLayerIcons['FLEET'].push(facIcon);
    } else if (facType === 'Service Provider') {
      this._allViewLayerIcons['SERVICE_PROVIDER'].push(facIcon);
    }
    return Promise.resolve();
  }

  public async createNewBranchIcon(
    name: string,
    branchID: string,
    addr1: string,
    addr2: string,
    location: google.maps.LatLng
  ): Promise<void> {
    const currMap = await this.mapService.getMapRefLoadPromise();
    if (currMap === null) {
      await this.mapService.getMapRefLoadPromise();
    }
    const branchIcon = new BranchIcon(currMap, name, branchID, addr1, addr2, location);

    this._allViewLayerIcons['BRANCH'].push(branchIcon);
    return Promise.resolve();
  }

  public async createPredictionGrid(coordinates: Object) {
    const currMap = await this.mapService.getMapRefLoadPromise();
    const pGrid = new PredictionGrid(currMap, coordinates, GridTypes.predGrid);
    this._PredictionGrids.push(pGrid);
  }

  // Called whenever there is an update to the prediction data (app load or submit new pred query)
  public createViewLayerGrids(gridArray: Array<Object>) {
    const currMap = this.mapService.currentMapRef;
    if (this._allViewLayerIcons[IconLayerTypes.Boundries] && this._allViewLayerIcons[IconLayerTypes.Boundries].length > 0) {
      this._allViewLayerIcons[IconLayerTypes.Boundries].forEach((el: PredictionGrid) => {
        el.setVisability(false);
      });
      this._allViewLayerIcons[IconLayerTypes.Boundries] = [];
    }
    if (gridArray && gridArray.length > 0) {
      gridArray.forEach((grd) => {
        const pGrid = new PredictionGrid(currMap, grd['gridBounds'], GridTypes.viewLayerGrid);
        this._allViewLayerIcons[IconLayerTypes.Boundries].push(pGrid);
      });
    }
  }

  public async createNewPredictionNumberIcon(
    predType: string,
    location: google.maps.LatLng,
    predCount: number,
    gridID: string): Promise<void> {
    const currMap = await this.mapService.getMapRefLoadPromise();
    const predIcon = new PredictionIcon(currMap, predType, location, predCount, gridID);
    this._allPredictionNumberMarkers[predType].push(predIcon);
    return Promise.resolve();
  }

  // Creates a new instance of an icon and adds it to our global array of icons for later access
  public async createNewTruckIcon(truckId: string, snapShot: Object): Promise<TruckIcon> {
    const currMap = await this.mapService.getMapRefLoadPromise();
    let newIcon: TruckIcon;
    newIcon = this.getTruckIconById(truckId) as TruckIcon;
    if (newIcon) {
      console.log('Error: truck icon already exists, not adding...');
      return;
    }
    newIcon = new TruckIcon(currMap, snapShot, false);
    if (newIcon.currentStatus === TruckStatus.DriverOutofVehicle) {
      this.addShowOVicon(newIcon);
    } else {
      // If the driver is not OV, make truck visible
      newIcon.setVisability(true);
    }
    this.addTruckIcon(truckId, newIcon);

    return Promise.resolve(newIcon);
  }

  // Creates a new instance of an icon and adds it to our global array of icons for later access
  public async createNewServiceIcon(serviceId: string, snapshot: Object): Promise<void> {
    const currMap = await this.mapService.getMapRefLoadPromise();
    let newIcon: ServiceIcon;
    newIcon = this.getServiceIconById(serviceId);
    if (newIcon) {
      console.log('Error: service icon already exists, not adding...');
      return;
    }
    newIcon = new ServiceIcon(currMap, snapshot, false);
    if (newIcon.currentStatus === ServiceStatus.Closed || newIcon.currentStatus === ServiceStatus.Killed) {
      this.addShowClosedCallIcon(newIcon);
    } else {
      // If call is not closed, make truck visible
      newIcon.setVisability(true);
    }
    this.addServiceIcon(serviceId, newIcon);

    return Promise.resolve();
  }

  public async createNewAirQualityIcon(id: string, snapshot: Object): Promise<void>{
    if(checkInStateBounds(snapshot['location']) ){
      const currMap = await this.mapService.getMapRefLoadPromise();
      let newIcon: AirQualityIcon;
      newIcon = this.getAirQualityIconById(id)
      if(newIcon){
        console.log('Error: AirQuality Icon already exists, not adding...');
        return;
      }
      newIcon = new AirQualityIcon(currMap, id, snapshot);
      this.addAirQualityIcon(newIcon);
      if(this.icnLayerServ.checkIconIsVisable(IconLayerTypes.AirQuality)){
        newIcon.setVisability(true);
      }else{
        newIcon.setVisability(false);
      }
    }
    
  }

  /**
   * @param truckId id of the marker to remove
   * @param removalType 'DELETE' removes the marker from the map and also destroys the icon object,
   * 'HIDE' only removes the marker from the map
   */
  public removeTruckIcon(truckId: string, removalType: string) {
    switch (removalType) {
      case 'DELETE':
        if (this.getTruckIconById(truckId)) {
          this.getTruckIconById(truckId).setVisability(false);
        }
        this.deleteTruckIconById(truckId);
        break;
      case 'HIDE':
        if (this.getTruckIconById(truckId)) {
          this.getTruckIconById(truckId).setVisability(false);
        }
        break;
    }
  }

  /**
   * @param serviceId id of the marker to remove
   * @param removalType 'DELETE' removes the marker from the map and also destroys the icon object,
   * 'HIDE' only removes the marker from the map
   */
  public removeServiceIcon(serviceId: string, removalType: string) {
    // Only delete and update if the icon currently exists
    if (this.getServiceIconById(serviceId)) {
      switch (removalType) {
        case 'DELETE':
          this.getServiceIconById(serviceId).setVisability(false);
          this.deleteServiceIconById(serviceId);
          break;
        case 'HIDE':
          this.getServiceIconById(serviceId).setVisability(false);
          break;
      }
    }
  }

  public removeAllPredIconsGrids() {
    this._allPredictionNumberMarkers['SERVICE'].forEach((icn) => {
      icn.setVisability(false);
    });
    this._allPredictionNumberMarkers['TOW'].forEach((icn) => {
      icn.setVisability(false);
    });
    this._PredictionGrids.forEach((icn) => {
      icn.setVisability(false);
    });

    this._allPredictionNumberMarkers['SERVICE'] = [];
    this._allPredictionNumberMarkers['TOW'] = [];
    this._PredictionGrids = [];
  }

  public showHideViewLayerIconsByType(hiddenIcons: Array<IconLayerTypes>, shownIcons: Array<IconLayerTypes>) {
    for (let i = 0; i < hiddenIcons.length; i++) {
      const currentIconType = hiddenIcons[i];
      this.blockingToggleIconLayerVisability(currentIconType, false);
    }
    for (let i = 0; i < shownIcons.length; i++) {
      const currentIconType = shownIcons[i];
      this.blockingToggleIconLayerVisability(currentIconType, true);
    }
  }

  public blockingToggleIconLayerVisability(icnType: IconLayerTypes, isVisable: boolean) {
    if (this._allViewLayerIcons[icnType].length > 0) {
      if (isVisable === true) {
        this._allViewLayerIcons[icnType].forEach((icn) => {
          if (icnType === IconLayerTypes.ServiceFacility) {
            if (this.facIconIsWithinMaxDist(icn.currentLocation, this.mapServ.currentLocation)) {
              icn.setVisability(true);
            }
          } else {
            icn.setVisability(true);
          }

        });
      } else {
        this._allViewLayerIcons[icnType].forEach((icn) => {
          icn.setVisability(false);
        });
      }
    }
  }

  private facIconIsWithinMaxDist(locA: google.maps.LatLng, locB: google.maps.LatLng): boolean {
    const aLat = Math.abs(locA.lat());
    const aLng = Math.abs(locA.lng());
    const bLat = Math.abs(locB.lat());
    const bLng = Math.abs(locB.lng());

    if (aLat > bLat + this.maxFacIconLatLngDist
      || aLat < bLat - this.maxFacIconLatLngDist
      || aLng > bLng + this.maxFacIconLatLngDist
      || aLng < bLng - this.maxFacIconLatLngDist) {
      return false;
    } else {
      return true;
    }
  }

  // oldestDate is in seconds, any service call with createdAt > oldestDate (date) will be removed
  public cleanOldServiceIcons(oldestDate: number) {
    const serviceCallsToRemove = [];
    this.allServiceIcons.forEach((el) => {
      if (el.createdAt.seconds < oldestDate) {
        serviceCallsToRemove.push(el.serviceCallSnapShot);
      }
    });
    serviceCallsToRemove.forEach((el) => {
      this.removeServiceIcon(el.serviceCallId, 'DELETE');
      const t = this.getTruckIconById(el.truckId);
      if (t && t.truckActiveServiceCallId === el.serviceCallId) {
        t.clearCurrentServiceCall();
      }
    });
  }
}
