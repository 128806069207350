import { FacilityService } from './../../services/facility-service/facility.service';
import { Component, Input, OnInit } from '@angular/core';
import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';
import { SettingsPanelAnimationService } from 'src/services/settings-panel-animation/settings-panel-animation.service';
import { GoogleMapService } from 'src/services/google-map/google-map.service';

@Component({
  selector: 'app-nav-bar-btn',
  templateUrl: './nav-bar-btn.component.html',
  styleUrls: ['./nav-bar-btn.component.scss']
})

export class NavBarBtnComponent implements OnInit {
  _iconBaseDir = 'assets/imgs/nav-icons/';
  callsUrl = `${this._iconBaseDir}person-icon.svg`;
  fleetUrl = `${this._iconBaseDir}tow-pic-icon.png`;
  predictionsUrl = `${this._iconBaseDir}predict-icon.png`;
  facilityUrl = `${this._iconBaseDir}facility-icon.svg`;
  legendUrl = `${this._iconBaseDir}legend-icon.svg`;
  iconLayerUrl = `${this._iconBaseDir}iconLayer-icon.svg`;
  logoUrl = `assets/imgs/sign-in/appLogo.png`;
  myImgUrl: String;
  Calls = SettingsPanelState.Calls;
  Fleet = SettingsPanelState.Fleet;
  Legend = SettingsPanelState.Legend;
  Predictions = SettingsPanelState.Predicitions;
  Facility = SettingsPanelState.Facility;
  IconLayers = SettingsPanelState.IconLayers;
  currentFacCount = 0;
  facilityDataLoaded = false;

  @Input() btnName: String;
  // NOTE: btnTypes: Calls, Fleet, Predictions, Legend, Facility, IconLayers, Logo
  @Input() btnType: SettingsPanelState;

  constructor(private settingsPanelAni: SettingsPanelAnimationService,
    private facilityService: FacilityService,
  ) { }
  settingsPanelState: SettingsPanelState;
  btnNormalColor = 'rgba(56, 56, 56, 0.50)';
  btnSelectColor = 'rgb(49, 49, 49)';
  isOpened = false;
  showFacToolTip = false;


  ngOnInit() {
    switch (this.btnType) {
      case SettingsPanelState.Logo:
        this.myImgUrl = this.logoUrl;
        break;
      case SettingsPanelState.Calls:
        this.myImgUrl = this.callsUrl;
        break;
      case SettingsPanelState.Fleet:
        this.myImgUrl = this.fleetUrl;
        break;
      case SettingsPanelState.Legend:
        this.myImgUrl = this.legendUrl;
        break;
      case SettingsPanelState.Predicitions:
        this.myImgUrl = this.predictionsUrl;
        break;
      case SettingsPanelState.Facility:
        this.myImgUrl = this.facilityUrl;
        break;
      case SettingsPanelState.IconLayers:
        this.myImgUrl = this.iconLayerUrl;
        break;
    }
    this.settingsPanelAni.settingsPanelStateListener.subscribe((panelState) => {
      this.settingsPanelState = panelState;
      this.isOpened = panelState === this.btnType ? true : false;
      if (this.btnType === SettingsPanelState.Facility && panelState === SettingsPanelState.Facility) {
        this.showFacToolTip = false;
      }
    });

    this.facilityService.facilityCookieExistsObs.subscribe(async cookieExists => {
      if (cookieExists !== null) {
        if (cookieExists === false) {
          this.showFacToolTip = true;
        } else {
          this.showFacToolTip = false;
        }
      }
    });
  }

  getIconSize() {
    switch (this.btnType) {
      case SettingsPanelState.Logo:
        break;
      case SettingsPanelState.Predicitions:

      case SettingsPanelState.Fleet:
        return '21px';
      case SettingsPanelState.Legend:
      case SettingsPanelState.Facility:
      case SettingsPanelState.IconLayers:
        return '19px';
    }
  }

  btnClick(e) {

    if (this.btnType === SettingsPanelState.Logo || e.target.classList.contains('fac-option')) {
      return;
    }
    // Clicking button while open should close it unless it is the facility search bar
    if (this.isOpened && this.settingsPanelAni.currentSettingsPanelState !== SettingsPanelState.Facility) {
      this.settingsPanelAni.updateSettingsPanelState(SettingsPanelState.Closed);
    } else {
      this.settingsPanelAni.updateSettingsPanelState(this.btnType);
    }

  }
  getSelectStyle() {
    if (this.settingsPanelState === this.btnType) {
      return this.btnSelectColor;
    } else {
      return this.btnNormalColor;
    }
  }

  hoverClass() {
    return this.btnType !== 'Logo';
  }
}
