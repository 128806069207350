import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MapMarkerService } from './../map-marker/map-marker.service';
import { FacilityService } from './../facility-service/facility.service';
import { ServiceIcon } from 'src/models/map-marker/serviceCall-icon.model';

@Injectable({
  providedIn: 'root'
})
export class CallsMenuService {
  private _serviceCallInfoObs: BehaviorSubject<Array<object>> = new BehaviorSubject<Array<object>>([]);
  private _serviceCallInfoInternal = {};

  get serviceCallInfo() {
    return this._serviceCallInfoObs.asObservable();
  }

  constructor(private mapMarkServ: MapMarkerService) {
    // Get notified when a service call is gone
    this.mapMarkServ.serviceIconDelObs.subscribe((id) => {
      if (id) {
        this.deleteServiceCall(id);
      }
    });
  }

  // Called from app component when service call listener recieves update
  public async updateServiceCallStatus(Sicon: ServiceIcon) {
    if (Sicon) {
      const tInfoOb = await this.extractServiceCallInfo(Sicon);
      this._serviceCallInfoInternal[Sicon.serviceCallId] = tInfoOb;
      this._serviceCallInfoObs.next(this.createCallInfoArray(this._serviceCallInfoInternal));
    }
  }

  private deleteServiceCall(id: string) {
    if (id in this._serviceCallInfoInternal) {
      delete this._serviceCallInfoInternal[id];
      this._serviceCallInfoObs.next(this.createCallInfoArray(this._serviceCallInfoInternal));
    }
  }

  private async extractServiceCallInfo(icon: ServiceIcon): Promise<Object> {
    const callInfoObj = {};
    const carYear = icon.getvehicleInfo('year') ? icon.getvehicleInfo('year') + ' ' : '';
    const carMake = icon.getvehicleInfo('make') ? icon.getvehicleInfo('make') + ' ' : '';
    const carModel = icon.getvehicleInfo('model') || '';
    let yearMakeModelStr = carYear + carMake + carModel;
    yearMakeModelStr = yearMakeModelStr.length < 1 ? 'None provided' : yearMakeModelStr;
    callInfoObj['status'] = icon.currentStatus;
    callInfoObj['serviceCallId'] = icon.serviceCallId;
    callInfoObj['callDate'] = icon.createdAtString || 'No date provided';
    callInfoObj['timeElapsed'] = icon.timeElapsedStr || 'No elapsed time';
    callInfoObj['memberId'] = icon.memberId || 'No ID provided';
    callInfoObj['yearMakeModel'] = yearMakeModelStr;
    callInfoObj['breakDownLocationAddress'] = icon.breakDownAddress || 'No address provided';
    callInfoObj['towDestination'] = await icon.towDestinationAddress() || 'No address provided';
    callInfoObj['eta'] = icon.currentETAstr || 'No current ETA';
    callInfoObj['serviceTypeVerbiage'] = icon.serviceTypeVerbiage || 'No service type provided';
    callInfoObj['headerRow'] = 'false';
    return callInfoObj;
  }

  private createCallInfoArray(sInfoO: Object) {
    return Object.values(sInfoO);
  }

}


