import { FirebaseDataService } from 'src/services/firebase-data/firebase-data.service';
import { MapMarkerService } from 'src/services/map-marker/map-marker.service';
import { FacilityService } from './../facility-service/facility.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TruckIcon } from 'src/models/map-marker/truck-icon.model';
import { ServiceIcon } from 'src/models/map-marker/serviceCall-icon.model';


@Injectable({
  providedIn: 'root'
})
export class FleetMenuService {
  private _truckInfoObs: BehaviorSubject<Array<object>> = new BehaviorSubject<Array<object>>([]);
  private _truckInfoInternal = {};
  private currentFacility: string;
  private driverNamesCache = {};

  constructor(private facServ: FacilityService, private mapMarkServ: MapMarkerService, private fireBaseData: FirebaseDataService) {
    this.facServ.currentSelectedFacilityIDObs.subscribe((facID) => {
      this.handleFacilityUpdate(facID);
    });
  }

  get truckInfo() {
    return this._truckInfoObs.asObservable();
  }

  private handleFacilityUpdate(newFac) {
    this.currentFacility = newFac;
    this._truckInfoInternal = {};
    const truckIconArray = this.mapMarkServ.allTruckIcons;
    if (truckIconArray && truckIconArray.length > 0) {
      truckIconArray.forEach((tIcon) => {
        this.updateTruckStatus(tIcon as TruckIcon);
      });
    } else {
      this.clearAllTrucks();
    }
  }

  clearAllTrucks() {
    this._truckInfoInternal = {};
    this._truckInfoObs.next(this.createTruckInfoArray(this._truckInfoInternal));
  }

  public async updateTruckStatus(Ticon: TruckIcon) {
    if (Ticon) {
      if (Ticon.facility_Id === null || Ticon.facility_Id !== this.currentFacility) {
        return;
      }
      const tInfoOb = await this.extractTruckInfo(Ticon);
      const tID = Ticon.full_truck_id;
      this._truckInfoInternal[tID] = tInfoOb;
      this._truckInfoObs.next(this.createTruckInfoArray(this._truckInfoInternal));
    }
  }

  private async extractTruckInfo(icon: TruckIcon): Promise<Object> {
    const truckInfoObj = {};
    truckInfoObj['status'] = icon.currentStatus;
    const snapInfo = {};
    snapInfo['driverID'] = 'No driver info';
    snapInfo['driverName'] = 'No driver info';
    snapInfo['truckType'] = 'Unavailable';
    let serviceCallIcon: ServiceIcon;
    if (icon.currentServiceCallSnapshotId) {
      serviceCallIcon = this.mapMarkServ.getServiceIconById(icon.currentServiceCallSnapshotId);
      if (serviceCallIcon) {
        snapInfo['driverID'] = serviceCallIcon.currentDriverId || 'No driver info';
        if (serviceCallIcon.currentDriverId) {
          if (snapInfo['driverID'] in this.driverNamesCache === false) {
            const drInfo = await this.fireBaseData.getTruckDriverInfo(snapInfo['driverID']);
            if (drInfo) {
              const lastName = drInfo.lastName == null || !drInfo.lastName ? '' : drInfo.lastName;
              const firstName = drInfo.firstName == null || !drInfo.firstName ? '' : drInfo.firstName;
              this.driverNamesCache[snapInfo['driverID']] = `${firstName} ${lastName}`;
            } else {
              this.driverNamesCache[snapInfo['driverID']] = `Unavailable`;
            }
          }
          snapInfo['driverName'] = this.driverNamesCache[snapInfo['driverID']];
        }
      }

    }
    if (icon.truckStatusSnapShot) {
      snapInfo['truckType'] = icon.truckStatusSnapShot.truckType || 'Unavailable';
    }
    truckInfoObj['truckId'] = icon.full_truck_id;
    truckInfoObj['callId'] = icon.currentServiceCallSnapshotId || 'No calls assigned';
    truckInfoObj['ETA'] = serviceCallIcon ? serviceCallIcon.currentETAstr : 'No calls assigned';
    truckInfoObj['trackingUrl'] = serviceCallIcon ? serviceCallIcon.trackingUrl : '';
    return { ...truckInfoObj, ...snapInfo };
  }

  private createTruckInfoArray(tInfoO: Object) {
    return Object.values(tInfoO);
  }

}
