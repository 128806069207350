import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-call-info-row',
  templateUrl: './service-call-info-row.component.html',
  styleUrls: ['./service-call-info-row.component.scss']
})
export class ServiceCallInfoRowComponent implements OnInit {
  @Input() cInfo: Array<object>;
  @Input() headerRow: string;
  @Input() scrollWidthFix: boolean;
  scrollWidthFixStr: string;
  expandRow = false;


  ngOnInit() {
    this.scrollWidthFixStr = this.scrollWidthFix === true ? 'true' : 'false';
  }

  toggleExpand() {
    if (this.headerRow !== 'true') {
      this.expandRow = !this.expandRow;
    }
  }


}
