import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoadingAnimationService {
  private _isOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
  }
  showLoader() {
    this._isOpen.next(true);
  }
  hideLoader() {
    this._isOpen.next(false);
  }

  get loaderListener() {
    return this._isOpen.asObservable();
  }

}
