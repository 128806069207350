import { AuthService } from './../auth/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { GoogleMapService } from 'src/services/google-map/google-map.service';
import * as fbCollections from 'src/services/firebase-data/fbCollections';
import { SQLDataService } from '../SQL-Data/SQL-Data';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { PreferenceDefaultServiceService } from '../preference-default-service/preference-default-service.service';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private prefDefaults: PreferenceDefaultServiceService,
    private SQLdataService: SQLDataService,
    private googleMapService: GoogleMapService,
    private afs: AngularFirestore,
    private authServ: AuthService) {

  }

  private _facilityInfo: Array<any>;
  private _facilityInfoHash: object;

  private _facilityNamesById: Object;
  private _currentSelectedFacilityIDObs: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _currentSelectedFacilityObs: BehaviorSubject<object> = new BehaviorSubject<object>(null);
  private _initialFacilitiesLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _facilityIsSelectedObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _facilityCookieExistsObs = new BehaviorSubject<boolean>(null); // True = there is a valid facility cookie

  get facilityCookieExistsObs(): Observable<boolean> {
    return this._facilityCookieExistsObs.asObservable();
  }

  // Note, because the filter component drives selection, cookie checking is done there and calls this
  setFacilityCookieLoadedObs(isLoaded: boolean) {
    this._facilityCookieExistsObs.next(isLoaded);
  }

  get currentSelectedFacilityID(): string {
    return this._currentSelectedFacilityIDObs.getValue();
  }
  get currentSelectedFacility(): object {
    return this._currentSelectedFacilityObs.getValue();
  }

  get currentSelectedFacilityIDObs(): Observable<string> {
    return this._currentSelectedFacilityIDObs.asObservable();
  }
  get currentSelectedFacilityObs(): Observable<object> {
    return this._currentSelectedFacilityObs.asObservable();
  }


  initialFacilitiesLoadedPromise(): Promise<void> {
    return new Promise((res) => {
      const sub = this._initialFacilitiesLoaded.asObservable().subscribe((isLoaded) => {
        if (isLoaded === true) {
          if (sub) {
            sub.unsubscribe();
          }
          res();
        }
      });
    });
  }


  async availableFacilities(): Promise<Array<object>> {
    if (this._facilityInfo == null) {
      await this.facilityInit();
      return Promise.resolve(this._facilityInfo);
    } else {
      return Promise.resolve(this._facilityInfo);
    }
  }

  // NOTE: This called by the facility filter
  async availableFacilityIds(): Promise<Array<string>> {
    if (this._facilityInfo == null) {
      await this.facilityInit();
    }
    const facIds = this._facilityInfo.map(fac => fac.facilityId);
    return Promise.resolve(facIds);
  }


  // USe to see if there are any facilities currently selected
  get facilityIsSelected(): boolean {
    if (this.currentSelectedFacilityID) {
      return true;
    } else {
      return false;
    }
  }

  // Use to wait until facility is selected
  public facilityIsSelectedPromise(): Promise<boolean> {
    return new Promise((res) => {
      if (this._facilityIsSelectedObs.getValue() === true) {
        return res(true);
      }
      const sub = this._facilityIsSelectedObs.subscribe((isSelected) => {
        if (isSelected === true) {
          sub.unsubscribe();
          return res(true);
        }
      });
    });
  }

  getFacilityInfoById(idStr: string) {
    if (!this._facilityInfoHash) {
      this._facilityInfoHash = {};
      this._facilityInfo.forEach(fac => {
        this._facilityInfoHash[fac.facilityId] = fac;
      });
    }
    return this._facilityInfoHash[idStr];
  }

  getFacilityNameById(idStr: string): Object {
    return this._facilityNamesById[idStr];
  }

  async getfacilityNameHashById(): Promise<Object> {
    if (this._facilityInfo == null) {
      await this.facilityInit();
    }
    return this._facilityNamesById;
  }

  async constructFacilityNamesById(): Promise<void> {
    const facilityList = await this.availableFacilities();
    this._facilityNamesById = {};
    for (let i = 0; i < facilityList.length; i++) {
      this._facilityNamesById[facilityList[i]['facilityId']] = facilityList[i]['name'];
    }
    return Promise.resolve();
  }

  private async updateMostRecentFacilitySelected(facID: string) {
    const sqlDat = await this.SQLdataService.facilitySQLDataByID(facID);
    let facObj = { id: facID, sqlData: sqlDat };
    facObj = { ...facObj, ...this.getFacilityInfoById(facID) };
    this._currentSelectedFacilityObs.next(facObj);
    this._currentSelectedFacilityIDObs.next(facID);
  }

  // Main function
  public async updateSelectedFacility(facID: string) {
    await this.updateMostRecentFacilitySelected(facID);
    this.prefDefaults.storeFacilityCookie(facID);

    if (facID) {
      this.zoomToFacility(facID);
    }
    if (facID) {
      this._facilityIsSelectedObs.next(true);
    }
  }

  async zoomToFacility(facilityId: string) {
    if (!facilityId) {
      return;
    }
    if (facilityId && facilityId.length > 0) {
      await this.SQLdataService.SQLdataInitialLoadPromise();
      const location = await this.SQLdataService.getFacilityLocationById(facilityId);
      if (location) {
        this.googleMapService.changeMapLocation(location);
      }
    }
  }


  async facilityInit(): Promise<void> {
    const user = await this.authServ.userInfo();
    this._facilityInfo = await this.queryAllFacilityInfo(user);
    this.constructFacilityNamesById();
    this._initialFacilitiesLoaded.next(true);
    return Promise.resolve();

  }

  async queryAllFacilityInfo(user: any): Promise<Array<any>> {
    let result = [];
    if ('facilities' in user && user.admin === true) {
      const snap = await this.afs.collection(fbCollections.facilities, ref => ref.where('otwEnabled', '==', true)).get().toPromise();
      result = snap.docs.map(fac => fac.data());
    } else if ('facilities' in user && user.facilities.length > 0) {
      for (let i = 0; i < user.facilities.length; i++) {
        const snap = await this.afs.collection(fbCollections.facilities).doc(user.facilities[i]).get().toPromise();
        result.push(snap.data());
      }
    }
    return result;
  }

}
