import { Component, HostListener, Inject, Input } from '@angular/core';

import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-fleet-info-row',
  templateUrl: './fleet-info-row.component.html',
  styleUrls: ['./fleet-info-row.component.scss'],
})
export class FleetInfoRowComponent {

  @Input() tInfo: Array<object>;
  @Input() headerRow: string;


}
