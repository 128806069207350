import { AuthService, AuthDialogComponent } from './../services/auth/auth.service';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { HttpRequestsService } from './../services/httpRequests/http-requests.service';
import { FleetMenuService } from './../services/fleet-menu/fleet-menu.service';
import { SQLDataService } from './../services/SQL-Data/SQL-Data';
import { PreferenceDefaultServiceService } from './../services/preference-default-service/preference-default-service.service';
import { FacilityFilterComponent } from './../components/facility-filter/facility-filter.component';
import { LoadingAnimationService } from './../services/loading-animation/loading-animation.service';
import { BrowserModule } from '@angular/platform-browser/';
import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';
import { GoogleMapsApiLoaderService } from '../services/google-maps-api-loader/google-maps-api-loader.service';
import { GoogleMapComponent } from '../components/google-map/google-map.component';
import { BROWSER_GLOBALS_PROVIDERS } from 'src/services/google-maps-api-loader/browser-globals';
import { GoogleMapService } from 'src/services/google-map/google-map.service';
import { FirebaseDataService } from 'src/services/firebase-data/firebase-data.service';
import { MapMarkerService } from 'src/services/map-marker/map-marker.service';
import { LegendComponent } from '../components/legend/legend.component';
import { SettingsPanelComponent } from '../components/settings-panel/settings-panel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatInputModule, MatCheckboxModule, MatIconModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderIconComponent } from '../components/loader-icon/loader-icon.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { NavBarBtnComponent } from '../components/nav-bar-btn/nav-bar-btn.component';
import { SettingsPanelAnimationService } from 'src/services/settings-panel-animation/settings-panel-animation.service';
import { CookieService } from 'ngx-cookie-service';
import { PredictionsComponent, ErrorDialogComponent } from '../components/predictions/predictions.component';
import { IconLayersComponent } from '../components/icon-layers/icon-layers.component';
import { FleetMenuComponent } from '../components/fleet-menu/fleet-menu.component';
import { FleetInfoRowComponent } from '../components/fleet-info-row/fleet-info-row.component';
import { PredictionsService } from 'src/services/predictions/predictions.service';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StatusDotComponent } from 'src/components/status-dot/status-dot.component';
import { CallsMenuComponent } from 'src/components/service-call-menu/service-call-menu.component';
import { ServiceCallInfoRowComponent } from '../components/service-call-info-row/service-call-info-row.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    AppComponent,
    GoogleMapComponent,
    LegendComponent,
    SettingsPanelComponent,
    FacilityFilterComponent,
    LoaderIconComponent,
    NavbarComponent,
    NavBarBtnComponent,
    PredictionsComponent,
    ErrorDialogComponent,
    IconLayersComponent,
    FleetMenuComponent,
    FleetInfoRowComponent,
    SignInComponent,
    AuthDialogComponent,
    StatusDotComponent,
    CallsMenuComponent,
    ServiceCallInfoRowComponent
  ],
  entryComponents: [ErrorDialogComponent, AuthDialogComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    MatAutocompleteModule
  ],
  providers: [
    GoogleMapsApiLoaderService,
    BROWSER_GLOBALS_PROVIDERS,
    GoogleMapService,
    FirebaseDataService,
    MapMarkerService,
    LoadingAnimationService,
    SettingsPanelAnimationService,
    PreferenceDefaultServiceService,
    CookieService,
    SQLDataService,
    FleetMenuService,
    PredictionsService,
    HttpRequestsService,
    AuthService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

