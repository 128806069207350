import { GenericIcon } from './generic-icon.model';
import {requestTypes} from 'src/services/httpRequests/http-requests.service'; 

export class ActiveFireLayer extends GenericIcon  {
  private _fireIconSize = 41;
  private iconFileFire = `${this.iconBaseDir}/viewLayer-icons/fire-icon.svg`;
  private _zIndex = 50;
  private _fireMarkers = [];
  private firePerimDat;
  private fireDat;
  private _geoJSONRef = null;
  private _fireDatUrl = 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/Active_Fires/FeatureServer/0/query?where=1%3D1&objectIds=&time=&geometry=&geometryType=esriGeometryEnvelope&inSR=&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&returnGeodetic=false&outFields=*&returnGeometry=true&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision=&outSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false&returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false&returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pjson&token=';
  private _firePerimUrl = 'https://opendata.arcgis.com/datasets/5da472c6d27b4b67970acc7b5044c862_0.geojson';
  private webReqServ;
  private autoUpdateRoutine = null;
  private autoUpdateInterval = 1000 * 60 * 6; //Time in ms
  private delayedLoad = false;

  constructor(currMap: google.maps.Map, webReqS) {
    super(currMap)
    this.isVisable = false;
    this._currentMap = currMap;
    this.webReqServ = webReqS;
    this.createLayer();
    this.initAutoUpdate();
  }

  private async createLayer() {
    
    this.fireDat = await this.webReqServ.sendWebRequest(requestTypes.get, this._fireDatUrl);
    this.firePerimDat = await this.webReqServ.sendWebRequest(requestTypes.get, this._firePerimUrl);
    // console.log(this.fireDat)
    for(const fireD of this.fireDat['features']){
      if(fireD['attributes']['FireMgmtComplexity'] !== null){
        this._fireMarkers.push(this.createFireMarker(fireD['attributes']))
      }
    }
    if(this.delayedLoad === true && this.isVisable === true){
      console.log('delayed load triggered')
      this.setVisability(true);
      this.delayedLoad =false;
    }
  }

  private createFireMarker(data): google.maps.Marker{
    const newIcon = {
      url: this.iconFileFire,
      scaledSize: new google.maps.Size(this._fireIconSize, this._fireIconSize),
      zIndex: this._zIndex
    };
    const markerOptions = { position: new google.maps.LatLng(data['InitialLatitude'], data['InitialLongitude']) };
    markerOptions['icon'] = newIcon;
    const marker = new google.maps.Marker(markerOptions);

    var infowindow = new google.maps.InfoWindow({
      content: this.generateFireInfoStr(data)
    });
    infowindow.set('pixelOffset', new google.maps.Size(0, -3));
  
    marker.addListener('click', () => {
      infowindow.open(this._currentMap, marker);
    });
    return marker
  }

  generateFireInfoStr(data): string {
    return `
    <div><b>Incident Name: </b>${data['IncidentName']}</div>
    <div><b>Fire Status: </b>${data['FireBehaviorGeneral']}</div>
    <div><b>Fire Management Complexity: </b>${data['FireMgmtComplexity']}</div>
    <div><b>Fire Discovery Date: </b>${new Date(data['FireDiscoveryDateTime']).toDateString()}</div>
    <div><b>Percent Contained: </b>${data['PercentContained']}</div>
    <div><b>Fire Perimeter Size: </b>${data['DailyAcres']} acres</div>
    `;
  }

  initAutoUpdate(){
    if(this.autoUpdateRoutine !== null){
      clearInterval(this.autoUpdateRoutine());
      this.autoUpdateRoutine = null;
    }
    this.autoUpdateRoutine  = setInterval(() => { this.autoUpdate(); }, this.autoUpdateInterval);

  }

  async autoUpdate(){
    if(this.isVisable === true){
      this.setVisability(false);
      await this.createLayer();
      this.setVisability(true);
    }
  }

  public setVisability(isVis: boolean): void {
    this.removeGeoJson();
    if (isVis) {
      for(const fIcon of this._fireMarkers){
        fIcon.setMap(this._currentMap);
      }

      this._geoJSONRef = this._currentMap.data.addGeoJson(this.firePerimDat);
    } 
    else {
      if(this._fireMarkers.length > 0){
        for(const fIcon of this._fireMarkers){
          fIcon.setMap(null);
        }
      }
    }
    if(this._geoJSONRef === null || this._fireMarkers.length < 1){
      this.delayedLoad = true
    }
    this.isVisable = isVis;
  }
  removeGeoJson(){
    if(this._geoJSONRef !== null){
    for (const feature of this._geoJSONRef){
      this._currentMap.data.remove(feature);
    }
  }
  }  
}



