import { GenericIcon } from './generic-icon.model';

export class PredictionIcon extends GenericIcon {
  private _predictionIconSize = 30;
  private _zIndex = 13;
  iconFileTow = `${this.iconBaseDir}/predict-tow.png`;
  iconFileService = `${this.iconBaseDir}/predict-service.png`;
  predictionType: string; // "TOW" || "SERVICE"
  predictionCount: number; // the number of predicted tow/service calls
  gridID: any;

  constructor(currMap: google.maps.Map,
    predictionType: string,
    currLoc: google.maps.LatLng,
    predictionCount: number,
    gridID: string) {
    super(currMap);
    this.gridID = gridID;
    this.predictionType = predictionType;
    this._currentLocation = currLoc;
    this.predictionCount = predictionCount;
    this.createMarker();
  }

  createMarker() {
    let titleData = this.predictionType === 'TOW' ? 'Predicted Tow Calls: ' : 'Predicted Service Calls: ';
    titleData += this.predictionCount.toString();

    // Uncomment below to show grids IDs
    // const titleData = 'Grid ID: ' + this.gridID;

    const labelData = this.predictionCount.toString();
    const newIcon = {
      url: this.predictionType === 'TOW' ? this.iconFileTow : this.iconFileService,
      scaledSize: new google.maps.Size(this._predictionIconSize, this._predictionIconSize),
      anchor: new google.maps.Point(this._predictionIconSize / 2, this._predictionIconSize / 2)
    };
    const markerOptions = {
      position: this._currentLocation,
      icon: newIcon,
      title: titleData,
      label: labelData,
      map: this._currentMap,
      zIndex: this._zIndex
    };
    this._marker = new google.maps.Marker(markerOptions);
  }

  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }

}

