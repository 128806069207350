import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TruckStatus, TruckColors } from 'src/models/map-marker/icon-status/truck-status';
import { ServiceStatus, ServiceColors } from 'src/models/map-marker/icon-status/service-status';


@Component({
  selector: 'app-status-dot',
  templateUrl: './status-dot.component.html',
  styleUrls: ['./status-dot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StatusDotComponent implements OnInit {

  @Input() tInfo: Array<object>;
  @Input() cInfo: Array<object>;
  @Input() headerRow: string;


  constructor() { }

  ngOnInit() {
  }

  getCircleColor() {
    if (this.headerRow !== 'true') {
      if (this.tInfo) {
        switch ((this.tInfo as any).status) {
          case TruckStatus.AssignedToMember:
            return TruckColors['Assigned to member'];
          case TruckStatus.DriverOutofVehicle:
            return TruckColors['Driver out of vehicle'];
          case TruckStatus.NotAssigned:
            return TruckColors['Not assigned'];
          case TruckStatus.OnSiteTowing:
            return TruckColors['Onsite/Towing'];
          default:
            return TruckColors['Not assigned'];
        }
      } else if (this.cInfo) {
        // console.log(this.cInfo['status']);
        switch ((this.cInfo as any).status) {
          case ServiceStatus.RequestedNoTruck:
            return ServiceColors['Requested - No Truck'];
          case ServiceStatus.RequestedTruckAssigned:
            return ServiceColors['Requested - Truck Assigned'];
          case ServiceStatus.OnSiteTowing:
            return ServiceColors['Onsite/Towing'];
          default:
            return ServiceColors['Closed'];
        }
      }
    }
  }

  getStatusTxt() {
    if (this.tInfo) {
      return this.tInfo['status'];
    } else if (this.cInfo) {
      return this.cInfo['status'];
    }
  }

}
