import { SQLDataService } from '../SQL-Data/SQL-Data';
import { FacilityService } from './../facility-service/facility.service';
import { Injectable } from '@angular/core';
import { IconLayerTypes } from '../icon-layers/icon-layers.service';
import { MapMarkerService } from '../map-marker/map-marker.service';

@Injectable({
  providedIn: 'root'
})
export class CarvingShapefilesService {
  private lastFac = null;

  constructor(private facServ: FacilityService, private sqlServ: SQLDataService, private markerServ: MapMarkerService) {
  }

  public initShapeFiles() {
    this.facServ.currentSelectedFacilityIDObs.subscribe((facID) => {
      this.handleFacUpdate(facID);
    });
  }

  private async handleFacUpdate(facID) {
    if (facID && facID.length > 0) {
      if (facID !== this.lastFac) {
        this.markerServ.removeAllCrvShapeFiles();
        const results = await this.sqlServ.getCarvingShapeFiles(facID);
        const parsedResults = this.parseShapeFiles(results);
        await this.markerServ.addAllCrvShapeFiles(parsedResults);
        this.lastFac = facID;
        this.markerServ.showSelectedCrvShapeFiles();
      }
    } else {
      await this.markerServ.removeAllCrvShapeFiles();
    }
    return Promise.resolve();
  }
  // 0 = grid id, 1 = grid name, 2 = facility id, 3 = spot type, 4 = backup level, 5 = coords
  private parseShapeFiles(geometryRows): object {
    const allShapeFiles = {};
    if (geometryRows) {
      for (let i = 0; i < geometryRows.length; i++) {
        const layerType = geometryRows[i].spot_type;
        if (layerType !== 'B' && layerType !== 'I' && layerType !== 'F') {
          continue;
        }
        const shapeFileObj = {};
        switch (layerType) {
          case 'B':
            shapeFileObj['shapeType'] = IconLayerTypes.ShapeFileBattery;
            break;
          case 'I':
            shapeFileObj['shapeType'] = IconLayerTypes.ShapeFileLight;
            break;
          case 'F':
            shapeFileObj['shapeType'] = IconLayerTypes.ShapeFileTow;
            break;
        }
        shapeFileObj['facilityID'] = geometryRows[i].facility_id;
        shapeFileObj['backupLevel'] = geometryRows[i].backup_level;

        const coordArray = this.pathsFromWkt(geometryRows[i].geography);

        shapeFileObj['coords'] = coordArray;
        if (shapeFileObj['shapeType'] in allShapeFiles) {
          allShapeFiles[shapeFileObj['shapeType']].push(shapeFileObj);
        } else {
          allShapeFiles[shapeFileObj['shapeType']] = [shapeFileObj];
        }
      }
      return allShapeFiles;
    }
    return null;
  }

  pathsFromWkt(wktPolygon: string) {
    const re = /\(([^()]+)\)/g;
    let results: RegExpExecArray;
    const shapes = new Array<string>();
    while ((results = re.exec(wktPolygon))) {
      shapes.push(results[1]);
    }
    return shapes.map(s =>
      s.split(',').map(l => {
        const coords = l.split(' ');
        return {
          lat: parseFloat(coords[1]),
          lng: parseFloat(coords[0])
        };
      })
    );
  }

}
