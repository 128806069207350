import { FacilityService } from './../facility-service/facility.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { MapMarkerService } from 'src/services/map-marker/map-marker.service';
import { Injectable } from '@angular/core';
import * as fbCollections from 'src/services/firebase-data/fbCollections';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseDataService {

  private truckUnsubTable = {};

  constructor(
    private mapMarker: MapMarkerService,
    private afs: AngularFirestore,
    private afRT: AngularFireDatabase,
    private facServ: FacilityService) {
  }

  private addTruckLocationUnsubscribe(truckId: string, unsub: Subscription) {
    if (truckId in this.truckUnsubTable && this.truckUnsubTable[truckId] !== null) {
      // tslint:disable-next-line: max-line-length
      console.log('Warning: unsub already exists for this ID, make sure you arent adding more than one listener!');
    }
    this.truckUnsubTable[truckId] = unsub;
  }


  unSubTruckLocationListenerById(truckId: string) {
    if (truckId in this.truckUnsubTable) {
      const currentSub = this.truckUnsubTable[truckId];
      if (currentSub) {
        currentSub.unsubscribe();
        delete this.truckUnsubTable[truckId];
      } else {
        console.log('Error: No listener exists for this id');
      }
    }
  }

  unSubAllTruckLocationListeners() {
    Object.keys(this.truckUnsubTable).forEach(id => {
      this.unSubTruckLocationListenerById(id);
    });
  }

  getTruckDriverInfo(drivId: string): Promise<any> {
    return this.afs.collection(fbCollections.drivers).doc(drivId).valueChanges().pipe(take(1)).toPromise();
  }

  /** Returns a date string for a given amount of hours in the past. Used for filtering service calls
   *
   * @param hoursBeforeCurrent - the number of hours before the current time that you want to get data for
   */
  calcServiceCallTimeFilter(hoursBeforeCurrent: number) {
    const newTime = new Date(Date.now());
    newTime.setHours(newTime.getHours() - hoursBeforeCurrent);
    return newTime.toISOString();
  }

  public handleAirQualityUpdates(snapshot: any){
    snapshot.forEach((change)=>{
      if(change.type ==='added'){
        this.mapMarker.createNewAirQualityIcon( change.payload.doc.id, change.payload.doc.data())
      }
      else if(change.type === 'removed'){
        this.mapMarker.removeAirQualityIconById(change.payload.doc.id);
      }else{
        //for updates, just remove the marker and add a new one with the new data
        this.mapMarker.removeAirQualityIconById(change.payload.doc.id);
        this.mapMarker.createNewAirQualityIcon( change.payload.doc.id, change.payload.doc.data())
      }
    })
  }

  public handleTruckUpdates(snapshot: any, truckStatusCallBack: Function, truckLocationCallBack: Function) {
    snapshot.forEach((change) => {
      this.truckUpdateFunction(change, truckStatusCallBack, truckLocationCallBack);
    });
  }

  private async truckUpdateFunction(change, truckStatusCallBack, truckLocationCallBack) {
    const truckId = change.payload.doc.data().truckId;
    const facilityId = change.payload.doc.data().facilityId;
    if (change.type === 'added') {
      await this.mapMarker.createNewTruckIcon(truckId, change.payload.doc.data());
      this.createTruckLocationListener(truckId, facilityId, truckLocationCallBack);
    } else if (change.type === 'removed') {
      this.removeTruckMarkerAndLocListenerById(truckId);
    }
    if (change.type !== 'removed') {
      truckStatusCallBack(change.payload.doc.data(), truckId);
    }
  }

  public handleServiceCallUpdates(snapshot: any, serviceCallStatusCallBack: Function) {
    snapshot.forEach((change) => {
      this.serviceCallUpdateFunction(change, serviceCallStatusCallBack);
    });
  }

  private async serviceCallUpdateFunction(change, serviceCallStatusCallBack) {
    const serviceCallId = change.payload.doc.data().serviceCallId;
    if (change.type === 'added') {
      await this.mapMarker.createNewServiceIcon(serviceCallId, change.payload.doc.data());
    } else if (change.type === 'removed') {
      this.removeServiceCallMarkerById(serviceCallId);
    }
    if (change.type !== 'removed') {
      serviceCallStatusCallBack(change.payload.doc.data());
    }
  }

  private removeTruckMarkerAndLocListenerById(truckId: string) {
    this.mapMarker.removeTruckIcon(truckId, 'DELETE');
    this.unSubTruckLocationListenerById(truckId);
  }

  private removeServiceCallMarkerById(serviceCallId: string) {
    this.mapMarker.removeServiceIcon(serviceCallId, 'DELETE');
  }

  async createTruckLocationListener(truckId: string, facilityId: string, callback: Function): Promise<any> {
    if (truckId in this.truckUnsubTable) {
      console.log('Error: Trying to add an existing listener, please debug');
      return Promise.reject();
    }

    const dbRef = this.afRT.database.app
      .database(this.facServ.getFacilityInfoById(facilityId).realtimeLocationDB)
      .ref(`truckLocations/${truckId}`);

    const sub = this.afRT.object(dbRef).valueChanges().subscribe(data => {
      callback(data, truckId);
    });
    this.addTruckLocationUnsubscribe(truckId, sub);
  }

}
