import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  _iconBaseDir = 'assets/imgs/';
  serviceNoTruckUrl = `${this._iconBaseDir}person_red.svg`;
  serviceTruckAssignedUrl = `${this._iconBaseDir}person_orange.svg`;
  serviceOnsiteTowingUrl = `${this._iconBaseDir}person_blue.svg`;
  serviceKilledCancelledUrl = `${this._iconBaseDir}person_black.svg`;
  truckOnsiteTowingUrl = `${this._iconBaseDir}tow-blue.svg`;
  truckUnassignedUrl = `${this._iconBaseDir}tow-default.svg`;
  truckOutofVehicleUrl = `${this._iconBaseDir}tow-red.svg`;
  truckAssignedUrl = `${this._iconBaseDir}tow-orange.svg`;
  predictedServiceCallsUrl = `${this._iconBaseDir}predict-service.png`;
  predictedTowCallsUrl = `${this._iconBaseDir}predict-tow.png`;
  predictedWarningZoneUrl = `${this._iconBaseDir}red-box.png`;
  availableWarningZoneUrl = `${this._iconBaseDir}yellow-box.png`;

  constructor() { }

  ngOnInit() {
  }

}
