import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './../services/auth/auth.service';
import { CarvingShapefilesService } from './../services/carving-shapefiles/carving-shapefiles.service';
import { BehaviorSubject } from 'rxjs';
import { PredictionsService } from './../services/predictions/predictions.service';
import { FleetMenuService } from './../services/fleet-menu/fleet-menu.service';
import { SQLDataService } from './../services/SQL-Data/SQL-Data';
import { FacilityService } from './../services/facility-service/facility.service';
import { Subscription } from 'rxjs';
import * as fbCollections from 'src/services/firebase-data/fbCollections';
import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { GoogleMapsApiLoaderService } from '../services/google-maps-api-loader/google-maps-api-loader.service';
import { FirebaseDataService } from '../services/firebase-data/firebase-data.service';
import { TruckIcon } from 'src/models/map-marker/truck-icon.model';
import { MapMarkerService } from '../services/map-marker/map-marker.service';
import { GoogleMapService } from 'src/services/google-map/google-map.service';
import { ServiceIcon } from 'src/models/map-marker/serviceCall-icon.model';
import { LoadingAnimationService } from 'src/services/loading-animation/loading-animation.service';
import { IconLayersService, IconLayerTypes } from 'src/services/icon-layers/icon-layers.service';
import { TruckStatus } from 'src/models/map-marker/icon-status/truck-status';
import { ServiceStatus } from 'src/models/map-marker/icon-status/service-status';
import 'hammerjs';
import { CallsMenuService } from './../services/calls-menu/calls-menu.service';
import { HostListener } from '@angular/core';
declare let google: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private facilityTrucksSubscription = null; // truck subscription for current facility
  private facilityServiceCallsSubcription = null; // service call subcription for current facility
  private _AppFullyLoadedObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _AppFullyLoadedStatic = false;
  private _IconLayerIsAwaitingAppLoad = false;
  private _isSignedIn = false;
  public showApp = false;
  public showLogin = false;
  private _markAppForReload = false; // used to determine if app needs to be reloaded after signout
  private hourlyTasksRoutineItv = null;
  private hourlyTasksRoutineDelay = 1000 * 60 * 60; // how often hourly tasks runs in milliseconds
  private debugQuickLoad = false; // use to skip predictions and shape file loading
  private fireIconLimit =10000;

  constructor(
    private mapsLoader: GoogleMapsApiLoaderService,
    private mapMarker: MapMarkerService,
    private firebaseData: FirebaseDataService,
    private googleMapService: GoogleMapService,
    private loaderAni: LoadingAnimationService,
    private facilityServ: FacilityService,
    private SQLdataServ: SQLDataService,
    private iconLayServ: IconLayersService,
    private fleetMenuServ: FleetMenuService,
    private predServ: PredictionsService,
    private sCallMenuServ: CallsMenuService,
    private carvingSh: CarvingShapefilesService,
    private authServ: AuthService,
    private afs: AngularFirestore) {


    this.loaderAni.showLoader();
    console.log(`Running in ${environment.name} environment...`);

    this.initHourlyTasksRoutine();

    this.iconLayServ.visableIcons.subscribe((visIcons) => {
      this.handleIconLayersChange(visIcons);
    });

    this.predServ.PredictionsAreLoadingObs.subscribe((isLoading) => {
      if (isLoading === 'true_ani' && this._isSignedIn === true) {
        this.loaderAni.showLoader();

      } else if ((isLoading === 'false' && this._AppFullyLoadedStatic === true) || this._isSignedIn === false) {
        this.loaderAni.hideLoader();
      }
    });

    this.facilityServ.currentSelectedFacilityIDObs.subscribe((id) => {
      if (id) {
        this.handleFacilityUpdate(id);
      }
    });

    this.authServ.isSignedInObs.subscribe((isSignedIn) => {
      this._isSignedIn = isSignedIn;
      if (isSignedIn !== null) {
        if (isSignedIn) {
          this.appSignIn();
        } else {
          this.appSignOut();
          this.loaderAni.hideLoader();
        }
      }
    });

  } // end of app constructor

  // Application Entry
  async appSignIn() {
    if (this._markAppForReload === true) {
      window.location.reload();
    }
    this.showLogin = false;
    this.showApp = true;

    // Initialize the application
    try {
      this.loadApp();
    } catch (err) {
      console.log('Application could not be loaded.');
    }
  }

  async appSignOut() {
    // If the application was fully loaded, app will need to reload after new sign-in
    if (this.showApp === true) {
      this._markAppForReload = true;
    }
    this.showLogin = true;
    this.showApp = false;
  }

  async loadApp() {

    this.loaderAni.showLoader();
    await this.mapsLoader.load(
      { apiKey: environment.mapK });
    await this.SQLdataServ.runInitialSQLdataJobs();
    this.loadBranchIcons();
    this.addAirQualityListeners();
    if (environment.name === 'prod' || this.debugQuickLoad === false) {
      this.carvingSh.initShapeFiles(); // starts waiting for fac selection to get shapefiles

    }
    let facSelected = false;
    this.loaderAni.hideLoader();
    // all data loading that is dependent on initial facility selection
    const sub = this.facilityServ.currentSelectedFacilityIDObs.subscribe(async (id) => {
      if (id) {
        if (sub) {
          sub.unsubscribe();
        }
        if (facSelected) { return; }
        this.loaderAni.showLoader();
        facSelected = true;
        this.googleMapService.initialMapZoom();
        if (environment.name === 'prod' || this.debugQuickLoad === false) {
          this.loadFacilityIcons();
          
          await this.loadInitialPrediction();
        }
        this.loaderAni.hideLoader();
        this._AppFullyLoadedStatic = true;
        this._AppFullyLoadedObs.next(true);
      }
    });
  }

  get AppFullyLoadedObs() {
    return this._AppFullyLoadedObs.asObservable();
  }

  AppFullyLoadedPromise(): Promise<void> {
    return new Promise((res) => {
      if (this._AppFullyLoadedStatic === true) {
        return res();
      }
      const sub = this.AppFullyLoadedObs.subscribe((isLoaded) => {
        if (isLoaded) {
          this._AppFullyLoadedStatic = true;
          if (sub) {
            sub.unsubscribe();
          }
          return res();
        }
      });
    });
  }

  async loadInitialPrediction(): Promise<void> {
    await this.predServ.startInitialPrediction();
    return Promise.resolve();
  }

  async loadFacilityIcons() {
    this.SQLdataServ.facilitySQLDataArray.forEach((fac) => {
      this.mapMarker.createNewFacilityIcon(fac.name,
        fac.facAdd1,
        fac.facAdd2,
        fac.facilityType,
        fac.facilitTypeLowerCase,
        new google.maps.LatLng(fac.lat, fac.lng));
    });
    return Promise.resolve();
  }

  async loadBranchIcons() {
    this.SQLdataServ.branchSQLDataArray.forEach((branch) => {
      this.mapMarker.createNewBranchIcon(
        branch.name, 
        branch.locationID,
        branch.facAdd1,
        branch.facAdd2,
        new google.maps.LatLng(branch.lat, branch.lng)
        );
    });
    return Promise.resolve();
  }

  private async handleIconLayersChange(visableIcons: Object) {
    const iconsToShow = visableIcons;
    const isFacilDependent = IconLayerTypes.Boundries in visableIcons
      || IconLayerTypes.WaitLocations in visableIcons
      || IconLayerTypes.ServiceFacility in visableIcons;
    let skipLoader = false;
    if (this._AppFullyLoadedStatic === false && this._IconLayerIsAwaitingAppLoad === false
      && isFacilDependent) {
      this.awaitFacilityDependentIconLayers();
      this._IconLayerIsAwaitingAppLoad = true;
      skipLoader = true;
    }

    // Do not show grid boundries or wait locations if no facility is selected
    if (this.facilityServ.currentSelectedFacilityID === null && isFacilDependent) {
      delete iconsToShow[IconLayerTypes.WaitLocations];
      delete iconsToShow[IconLayerTypes.Boundries];
      delete iconsToShow[IconLayerTypes.ServiceFacility];
    }

    const hiddenIcons = Object.values(IconLayerTypes).filter((icn) => icn in visableIcons === false);
    this.mapMarker.showHideViewLayerIconsByType(hiddenIcons, <IconLayerTypes[]>Object.keys(iconsToShow));
    return Promise.resolve();
  }


  private async awaitFacilityDependentIconLayers() {
    await this.AppFullyLoadedPromise();
    const sub = this.iconLayServ.visableIcons.subscribe((icns) => {
      this.handleIconLayersChange(icns);
      this._IconLayerIsAwaitingAppLoad = false;
      if (sub) {
        sub.unsubscribe();
      }
    });
  }

  // Takes an array of facility ids and updates the map do add/remove trucks
  async handleFacilityUpdate(facID: string) {
    this.removeCurrentFacility();
    this.unsubCurrentFacilityTrucks();
    this.unsubCurrentFacilityServiceCalls();

    if (facID) {
      this.addTruckListeners(facID);
      this.addServiceCallListeners(facID);
    }
  }

  addAirQualityListeners(){
    this.afs.collection(fbCollections.airQuality, ref =>
      ref.limit(this.fireIconLimit)
      )
      .stateChanges()
      .subscribe((snapshot)=> 
      this.firebaseData.handleAirQualityUpdates(snapshot))
  }

  addTruckListeners(facID: string) {

    const unsub = this.afs.collection(fbCollections.trucks, ref =>
      ref.where('facilityId', '==', facID))
      .stateChanges()
      .subscribe((snapshot) => this.firebaseData.handleTruckUpdates(
        snapshot, this.truckStatusUpdate.bind(this), this.truckLocationUpdate.bind(this)));

    this.addFacilityTrucksUnsub(unsub);

  }

  addServiceCallListeners(facID: string) {
    const oneDayAgo = this.getOneDayAgo(true);
    const unsub = this.afs.collection(fbCollections.serviceCalls, ref =>
      ref
        .where('potentialFacilityIds', 'array-contains', facID)
        .where('createdAt', '>=', oneDayAgo)
    )
      .stateChanges()
      .subscribe((snapshot) => {
        const filteredSnapshot = [];
        snapshot.forEach((el) => {
          const payload = el.payload.doc.data();

          // ***IMPORTANT, do not use the facID argument here because it might be out of date, use the one from facility service
          const mostCurrentSelectedFacID = this.facilityServ.currentSelectedFacilityID;
          // Only perform updates if there is no current facility or if the current facility matches this call
          if (!payload['facilityId'] || mostCurrentSelectedFacID === payload['facilityId']) {
            filteredSnapshot.push(el);
          } else {
            // If call facility does not match current facility then delete marker if it exists
            this.mapMarker.removeServiceIcon(payload['serviceCallId'], 'DELETE');
          }
        });

        this.firebaseData.handleServiceCallUpdates(
          filteredSnapshot, this.serviceCallStatusUpdate.bind(this));

      });
    this.addFacilityServiceCallsUnsub(unsub);
  }

  unsubCurrentFacilityTrucks() {
    if (this.facilityTrucksSubscription !== null) {
      this.facilityTrucksSubscription.unsubscribe();
      this.facilityTrucksSubscription = null;
    }
  }

  unsubCurrentFacilityServiceCalls() {
    if (this.facilityServiceCallsSubcription !== null) {
      this.facilityServiceCallsSubcription.unsubscribe();
      this.facilityServiceCallsSubcription = null;
    }
  }

  addFacilityTrucksUnsub(unsub: Subscription) {
    if (this.facilityTrucksSubscription === null) {
      this.facilityTrucksSubscription = unsub;
    } else {
      console.log('Error: Facility already in unsub list, make sure that it is unsubed before listening!');
    }
  }

  addFacilityServiceCallsUnsub(unsub: Subscription) {
    if (this.facilityServiceCallsSubcription === null) {
      this.facilityServiceCallsSubcription = unsub;
    } else {
      console.log('Error: Facility already in unsub list, make sure that it is unsubed before listening!');
    }
  }

  removeCurrentFacility() {
    this.mapMarker.removeAllTruckIcons();
    this.mapMarker.removeAllServiceCallIcons();
    this.firebaseData.unSubAllTruckLocationListeners();
  }

  // Updates the marker position for a given marker
  truckLocationUpdate(truckLocationSnapshot: any, truckId: string) {
    if (!truckLocationSnapshot) {
      return;
    }
    // Get the icon
    const truckIcon = <TruckIcon>this.mapMarker.getTruckIconById(truckId);
    if (truckIcon) {
      const latlng = new google.maps.LatLng(truckLocationSnapshot.lat, truckLocationSnapshot.lng);
      truckIcon.updateLocation(latlng);
      truckIcon.updateMarker(null, truckLocationSnapshot.bearing);
    } else {
      console.log('Error: Location cant be set - this truck does not have an icon');
    }
  }

  // Updates the truckStatus snapshot for a given marker
  async truckStatusUpdate(truckStatusSnapshot: any, truckId: string) {
    const icon = this.mapMarker.getTruckIconById(truckId);
    const truckIcon = <TruckIcon>icon;
    // first check if there were any changes to the device serial, if so then update the location listener
    if ('deviceSerial' in truckStatusSnapshot) {
      // if the device serial has changed, we need to change the locaiton listener
      if (truckStatusSnapshot.deviceSerial !== truckIcon.currentDeviceSerial) {
        this.firebaseData.unSubTruckLocationListenerById(truckId);
        this.firebaseData.createTruckLocationListener(
          truckId,
          this.facilityServ.currentSelectedFacilityID,
          this.truckLocationUpdate.bind(this));
      }
    } else {
      // If there is currently no device serial, stop listening for location updates
      this.firebaseData.unSubTruckLocationListenerById(truckId);
    }

    const prevStatus = truckIcon.currentStatus;
    // update the truck status snapshot for the marker
    truckIcon.updateTruckStatusSnapShot(truckStatusSnapshot);

    if (truckIcon.currentStatus !== prevStatus) {
      // Check if the status is OV and update our map maker OV filter array
      this.updateTruckOVFilter(truckIcon);
    }

    // Whenever the status of the truck updates we need to update the icon
    if (truckStatusSnapshot.activeServiceCalls !== null && truckStatusSnapshot.activeServiceCalls.length > 0) {
      const mostRecentServiceCallId = truckStatusSnapshot.activeServiceCalls[0];
      const serviceIcon = <ServiceIcon>this.mapMarker.getServiceIconById(mostRecentServiceCallId);
      const shouldIgnore = this.checkCallDateIgnore(serviceIcon);
      // Check if we have an existing service call and if the current call is newer than the one we have
      if (shouldIgnore === false && (truckIcon.hasServiceCallSnapShot === false ||
        truckIcon.currentServiceCallSnapshotId !== mostRecentServiceCallId)) {

        // First check if there is already a service icon for this call so we can get the snapshot
        if (serviceIcon) {
          truckIcon.clearCurrentServiceCall();
          truckIcon.updateCurrentServiceCall(serviceIcon.serviceCallSnapShot);
        } else {
          // this.firebaseData.addSingleServiceCall(mostRecentServiceCallId, this.serviceCallStatusUpdate.bind(this), facilityId);
          console.log('note: truck has a service call with no marker yet, make sure it gets added first and this truck knows about it');
        }
      }
    } else {
      truckIcon.clearCurrentServiceCall();
    }

    // After all updates, update the fleet menu
    this.fleetMenuServ.updateTruckStatus(truckIcon);

  }

  /** Handles all firebase updates for service calls
  *@param serviceCallStatusSnapshot - The current service call snapshot received from firebase
  */
  serviceCallStatusUpdate(serviceCallSnapshot: any) {
    let truckIcon: TruckIcon;
    let serviceCallIcon: ServiceIcon;
    let assignedTruckID: string;
    let assignedFacilityID: string;
    if (serviceCallSnapshot) {
      if (serviceCallSnapshot.truckId && serviceCallSnapshot.facilityId) {
        assignedTruckID = serviceCallSnapshot.truckId;
        assignedFacilityID = serviceCallSnapshot.facilityId;
      }
      const iconSC = this.mapMarker.getServiceIconById(serviceCallSnapshot.serviceCallId);
      if (iconSC) {
        serviceCallIcon = <ServiceIcon>iconSC;
        const prevStatus = serviceCallIcon.currentStatus;
        serviceCallIcon.updateServiceCall(serviceCallSnapshot);
        if (prevStatus !== serviceCallIcon.currentStatus) {
          this.updateCallClosedFilter(serviceCallIcon);
        }

        if (assignedTruckID) {
          const iconTR = this.mapMarker.getTruckIconById(assignedTruckID);
          if (iconTR) {
            // Note: We should only update the truck status if we are the current service call it is assigned to
            truckIcon = <TruckIcon>iconTR;
            const prevTruckStatus = truckIcon.currentStatus;
            if (truckIcon.hasActiveServiceCall && truckIcon.truckActiveServiceCallId === serviceCallSnapshot.serviceCallId) {
              truckIcon.updateCurrentServiceCall(serviceCallSnapshot);
              if (prevTruckStatus !== truckIcon.currentStatus) {
                this.updateTruckOVFilter(truckIcon);
              }
            }
          }
        }
        // After all status updates, update Fleet Menu
        if (truckIcon) {
          this.fleetMenuServ.updateTruckStatus(truckIcon);
        }

        // Also update the service call menu
        this.sCallMenuServ.updateServiceCallStatus(serviceCallIcon);
      }
    }
  }

  updateTruckOVFilter(truckIcon: TruckIcon) {
    if (truckIcon.currentStatus === TruckStatus.DriverOutofVehicle) {
      this.mapMarker.addShowOVicon(truckIcon);
    } else {
      this.mapMarker.removeShowOVicon(truckIcon);
    }
  }

  updateCallClosedFilter(callIcon: ServiceIcon) {
    if (callIcon.currentStatus === ServiceStatus.Closed || callIcon.currentStatus === ServiceStatus.Killed) {
      this.mapMarker.addShowClosedCallIcon(callIcon);
    } else {
      this.mapMarker.removeShowClosedCallIcon(callIcon);
    }
  }

  // Use to igonore calls that are older than 24hrs, true = older than 24hrs old (ignore call)
  checkCallDateIgnore(serviceCallicon: ServiceIcon): boolean {
    if (serviceCallicon) {
      const createdAt = serviceCallicon.createdAt.seconds;
      // If one day ago is later than the created at date, return true
      if (this.getOneDayAgo(false, true) > createdAt) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // Returns one day ago, return date = true returns a date object, returnSeconds = true returns result in seconds
  getOneDayAgo(returnDate = false, returnSeconds = false): any {
    const oneDay = 60 * 60 * 24 * 1000;
    const result = Date.now() - oneDay;
    if (returnDate === true) {
      return new Date(result);
    } else {
      if (returnSeconds === true) {
        return result / 1000;
      } else {
        return result;

      }
    }
  }

  initHourlyTasksRoutine() {
    if (this.hourlyTasksRoutineItv !== null) {
      clearInterval(this.hourlyTasksRoutineItv());
      this.hourlyTasksRoutineItv = null;
    }
    const nextHour = new Date();
    nextHour.setHours(nextHour.getHours() + 1);
    nextHour.setMinutes(0);
    nextHour.setSeconds(0);

    const difference = +nextHour - +new Date();
    setTimeout(() => { this.hourlyTasksRoutine(); }, difference);
  }

  // For things that need to be done every hour
  hourlyTasksRoutine() {
    if (this.facilityServ.facilityIsSelected === true) {
      console.log('Performing hourly tasks...');
      this.mapMarker.cleanOldServiceIcons(this.getOneDayAgo(false, true));
      this.predServ.updatePredictionsForNextHour();
      if (this.hourlyTasksRoutineItv === null) {
        this.hourlyTasksRoutineItv = setInterval(() => { this.hourlyTasksRoutine(); }, this.hourlyTasksRoutineDelay);
      }
    }

  }

  // Use for debug only to trigger events
  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   if (environment.name === 'dev' && event.key === 'Enter') {
  //     this.hourlyTasksRoutine();
  //   }
  // }

}



