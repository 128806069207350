import { GenericIcon } from './generic-icon.model';
import { ServiceStatus, ServiceColors } from './icon-status/service-status';

export class ServiceIcon extends GenericIcon {
  private _currentStatus: ServiceStatus;
  private _serviceCallSnapShot: any = null;
  private _serviceIconSize = 22;
  private _zIndex = 11;
  private _currentETAstr;
  private _currentDriver;
  private _towDestinationAddress: string;

  iconFiles = new Map(
    [
      [ServiceStatus.RequestedNoTruck, `${this.iconBaseDir}/person_red.svg`],
      [ServiceStatus.RequestedTruckAssigned, `${this.iconBaseDir}/person_orange.svg`],
      [ServiceStatus.OnSiteTowing, `${this.iconBaseDir}/person_blue.svg`],
      [ServiceStatus.Closed, `${this.iconBaseDir}/person_black.svg`],
      [ServiceStatus.Killed, `${this.iconBaseDir}/person_black.svg`]
    ]
  );

  constructor(currMap: google.maps.Map, snapShot: object, isVisible: boolean) {
    super(currMap);
    this.isVisable = isVisible;
    this._iconSize = this._serviceIconSize;
    this.updateServiceCall(snapShot);
  }

  get serviceCallSnapShot(): any {
    return this._serviceCallSnapShot;
  }

  get serviceCallId(): any {
    if (this.serviceCallSnapShot) {
      return this.serviceCallSnapShot.serviceCallId;
    } else {
      return null;
    }
  }

  get currentStatus(): ServiceStatus {
    return this._currentStatus;
  }

  set currentStatus(status: ServiceStatus) {
    this._currentStatus = status;
  }

  get currentETAstr(): string {
    return this._currentETAstr;
  }

  get currentETA(): any {
    if (this.serviceCallSnapShot) {
      return this.serviceCallSnapShot.eta['time'];
    } else {
      return null;
    }
  }

  private genTimeElapsedStr(msec) {
    const num = msec / 1000;
    const hours = Math.floor(num / 3600);
    const minutes = Math.floor((num - (hours * 3600)) / 60);
    let hrStr = hours.toString() + 'hr ';
    const minStr = minutes.toString() + 'min ';
    if (hours < 1) { hrStr = ''; }
    return hrStr + minStr;
  }

  get timeElapsedStr(): string {
    if (this.createdAt) {
      return this.genTimeElapsedStr((new Date().getTime() - this.createdAt.toDate().getTime()));
    }
  }

  get createdAt(): any {
    if (this.serviceCallSnapShot) {
      return this.serviceCallSnapShot.createdAt;
    } else {
      return null;
    }
  }

  get breakDownAddress(): string {
    if (this.serviceCallSnapShot && this.serviceCallSnapShot.breakdownLocationAddress) {
      return this.formatAddress(this.serviceCallSnapShot.breakdownLocationAddress);
    } else {
      return null;
    }
  }
  get memberId(): string {
    if (this.serviceCallSnapShot) {
      return this.serviceCallSnapShot.memberId;
    } else {
      return null;
    }
  }

  get serviceTypeVerbiage(): string {
    if (this.serviceCallSnapShot) {
      return this.capitalizeString(this.serviceCallSnapShot.serviceTypeVerbiage);
    } else {
      return null;
    }
  }

  get towDestinationLatLng(): google.maps.LatLng {
    if (this.serviceCallSnapShot) {
      const lat = this.serviceCallSnapShot.towDestinationLat;
      const long = this.serviceCallSnapShot.towDestinationLong;
      if (lat && long) {
        return new google.maps.LatLng(lat, long);
      }
    }
    return null;
  }

  formatAddress(addr: string): string {
    const strp = addr.replace(', USA', '');
    return this.capitalizeString(strp);
  }

  // infoType = make, model, year
  public getvehicleInfo(infoType: string) {
    if (this.serviceCallSnapShot) {
      let result = null;
      switch (infoType) {
        case 'make':
          result = this.serviceCallSnapShot.serviceVehicleMake ? this.serviceCallSnapShot.serviceVehicleMake.toUpperCase() : null;
          break;
        case 'model':
          result = this.serviceCallSnapShot.serviceVehicleModel ? this.serviceCallSnapShot.serviceVehicleModel.toUpperCase() : null;
          break;
        case 'year':
          result = this.serviceCallSnapShot.serviceVehicleYear;
          break;
        default:
          return null;
      }
      return result;
    } else {
      return null;
    }
  }

  // formatType: 'full' = returns whole object, 'short' = returns only address
  public async towDestinationAddress(): Promise<string> {
    return null;
    // if (!this._towDestinationAddress) {
    //   const latlong = this.towDestinationLatLng;
    //   if (latlong) {
    //     const result = await this.reverseGeoCode(latlong);
    //     if (result['formatted_address']) {
    //       this._towDestinationAddress = this.formatAddress(result['formatted_address']);
    //     }
    //   }
    // }

    // if (this._towDestinationAddress) {
    //   return this._towDestinationAddress;
    // } else {
    //   return null;
    // }
  }

  reverseGeoCode(latlng: google.maps.LatLng): Promise<any> {
    return new Promise((res) => {
      const geocoder = new google.maps.Geocoder;
      geocoder.geocode({ 'location': latlng }, (results, status) => {
        if (status as unknown as String === 'OK') {
          if (results[0]) {
            return res(results[0]);
          } else {
            console.log(`Could not find address for call ${this.serviceCallId}, latlong: ${latlng.lat} ${latlng.lng}`);
          }
        } else {
          console.log(`Reverse geocode error for ${this.serviceCallId}`);
          console.log(status as unknown as String);
        }
        return res(null);
      });

    });

  }

  get createdAtString(): string {
    if (this.serviceCallSnapShot) {
      const createdAt = this.serviceCallSnapShot.createdAt;
      if (createdAt) {
        return this.formatDate(createdAt);
      }
    }
    return null;
  }

  get currentDriverId(): string {
    if (this._serviceCallSnapShot) {
      return this._serviceCallSnapShot.driverId;
    } else {
      return null;
    }
  }

  get currenttruckId(): string {
    if (this._serviceCallSnapShot) {
      return this._serviceCallSnapShot.truckId;
    } else {
      return null;
    }
  }

  get facilityId(): string {
    if (this.serviceCallSnapShot) {
      return this.serviceCallSnapShot.facilityId;
    } else {
      return null;
    }
  }


  get trackingUrl(): string {
    return `${this.serviceCallSnapShot.memberBaseUrl}/?token=${this.serviceCallSnapShot.memberAccessToken}&metrics=false`;
  }
  set currentETAstr(eta: string) {
    this._currentETAstr = eta;
  }

  // Set the correct icon given the current status, create a marker if it does not exit
  updateMarker(iconUrl: string, infoStr: string): void {
    if (this.hasMarker() !== true) {
      this.createMarker(iconUrl);
    } else {
      this._marker.setIcon({ url: iconUrl, scaledSize: new google.maps.Size(this.iconSize, this.iconSize) });
    }
    this.updateInfoWindow(infoStr);
  }


  createMarker(iconUrl: string) {
    const newIcon = { url: iconUrl, scaledSize: new google.maps.Size(this.iconSize, this.iconSize) };
    let map = null;
    if (this.isVisable) {
      map = this._currentMap;
    }
    const markerOptions = {
      position: this._currentLocation,
      map: map,
      zIndex: this._zIndex
    };
    if (iconUrl) { markerOptions['icon'] = newIcon; }
    this._marker = new google.maps.Marker(markerOptions);
    this.setMarkerInfoWindow();
    // fix for info window flicker
    this._currentInfoWindow.set('pixelOffset', new google.maps.Size(0, -3));
  }

  // Keeps our current service call information up to date and updates the icon itself
  public updateServiceCall(snapshot: any) {
    this._serviceCallSnapShot = snapshot;
    this.updateLocation(new google.maps.LatLng(snapshot.breakdownLat, snapshot.breakdownLong));
    // After updating the snapshot we need to update our icon
    this.updateServiceCallStatus();

  }

  updateLocation(latlng: google.maps.LatLng) {
    this._currentLocation = latlng;
    if (this._marker) {
      this._marker.setPosition(latlng);
    }
  }

  private updateServiceCallStatus(): void {
    // First check if we have all the info we need to update the status: a serviceCallSnapShot and truckStatusSnapShot
    if (this.serviceCallSnapShot) {
      if (this.serviceCallSnapShot.currentStatus === 'CL' || this.serviceCallSnapShot.currentStatus === 'KI') {
        if (this.serviceCallSnapShot.currentStatus === 'KI') {
          this.currentStatus = ServiceStatus.Killed;
        } else {
          this.currentStatus = ServiceStatus.Closed;
        }

      } else {
        if (this.serviceCallSnapShot.currentStatus !== 'SP' &&
          (this.serviceCallSnapShot.truckId !== null && this.serviceCallSnapShot.facilityId !== null)) {
          if (this.serviceCallSnapShot.currentStatus === 'OL' || this.serviceCallSnapShot.currentStatus === 'TW') {
            this.currentStatus = ServiceStatus.OnSiteTowing;
          } else {
            this.currentStatus = ServiceStatus.RequestedTruckAssigned;
          }
        } else {
          this.currentStatus = ServiceStatus.RequestedNoTruck;
        }
      }
      const iconUrl = this.iconFiles.get(this.currentStatus);
      this.updateMarker(iconUrl, this.generateServiceCallInfoStr());
    }

  }
  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }

  formatDate(d) {
    const options = {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    };
    const init = d.toDate().toLocaleString('en-US', options).split(', ');
    return `${init[1]}, ${init[0]}`;
  }

  generateServiceCallInfoStr(): string {
    let assignedTruck = 'No truck assigned';
    let eta = null;
    let createdAt = this.serviceCallSnapShot.createdAt;
    if (this.currentStatus !== ServiceStatus.RequestedNoTruck) {
      assignedTruck = `${this.serviceCallSnapShot.facilityId}_${this.serviceCallSnapShot.truckId}`;
      eta = this.serviceCallSnapShot.eta && this.serviceCallSnapShot.eta !== null ? this.serviceCallSnapShot.eta['time'] : null;
    }
    if (createdAt) {
      createdAt = this.formatDate(createdAt);
    }
    if (eta) {
      eta = this.formatDate(eta);
    } else {
      eta = 'No current ETA';
    }
    this.currentETAstr = eta;
    return `
    <div style="font-size:  1.03em">
      <div style="text-align:center; margin-bottom: 10px;">
        <div style="color:#4563fd; font-size: 18px; font-weight:500">Service Call Status</div>
        <div style="font-size:12px; font-weight: 400;color: #717171;">
          (Service ID: ${this.serviceCallSnapShot.serviceCallId || `Information not received`})
        </div>
        <hr style="border: 0px; border-bottom: .1em solid #dedede;">
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">Current Status:</span>
        <span style="font-weight:bold; color:${ServiceColors[this._currentStatus]}">${this._currentStatus || `Information not received`}
        </span>
        </div>
      <div style="margin-bottom: 8px"><span style="font-weight: 500; color:#000000">Created At:</span>
        <span style="color:#000000">${createdAt || `Information not received`}</span>
      </div>
      <div style="margin-bottom: 8px"><span style="font-weight: 500; color:#000000">Service Type:</span>
        <span style="color:#000000">${this.serviceTypeVerbiage || `Information not received`}
        </span>
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">Assigned Truck:</span> <span style="color:#000000">${assignedTruck}
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">ETA:</span> <span style="color:#000000">${eta || `Information not received`}</span>
      </div>
    </div>
    `;
  }

  capitalizeString(str: string) {
    if (str) {
      const splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }
    return null;
  }
}

