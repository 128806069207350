import { IconLayerTypes, IconLayersService } from './../icon-layers/icon-layers.service';
import { FacilityService } from './../facility-service/facility.service';
import { MapMarkerService } from './../map-marker/map-marker.service';
import { GoogleMapService } from './../google-map/google-map.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SQLDataService } from '../SQL-Data/SQL-Data';
import { Injectable } from '@angular/core';
const moment = require('moment-timezone');

@Injectable({
  providedIn: 'root'
})
export class PredictionsService {

  // Uses lat_lng for key
  private _GridAndPredictionsHash = null;
  private _GridAndPredictionsArray = null;
  private _shouldRunInitialPrediction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _InitialPredictionsLoadedObs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _PredictionsAreLoadingObs: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _autoFetchPredictionsNextHr: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private lastSelectedPredHour: number;
  private _InitialPredictionsLoadedStatic = false;
  private _predIconPadding = 0.011;
  private _previousFacilityID: string;

  constructor(
    private bigQueryServ: SQLDataService,
    private mapService: GoogleMapService,
    private markerServ: MapMarkerService,
    private facilServ: FacilityService,
    private icnLayerServ: IconLayersService

  ) {
    this.facilServ.currentSelectedFacilityIDObs.subscribe(async (facID) => {
      if (facID !== this._previousFacilityID) {
        this._previousFacilityID = facID;
        this.markerServ.removeAllPredIconsGrids();
        this.markerServ.blockingToggleIconLayerVisability(IconLayerTypes.Boundries, false);
        if (this._InitialPredictionsLoadedStatic) {
          // NOTE: Need a way to convert currently selected time to new timezone of facility
          await this.updatePredictionData();
          if (this.icnLayerServ.checkIconIsVisable(IconLayerTypes.Boundries)) {
            this.markerServ.blockingToggleIconLayerVisability(IconLayerTypes.Boundries, true);
          }
        }
      }
    });
  }

  // Listened to by the predictions component to submit a new prediction request
  get AutoFetchPredictionsNextHrObs() {
    return this._autoFetchPredictionsNextHr.asObservable();
  }

  get InitialPredictionsLoadedObs() {
    return this._InitialPredictionsLoadedObs.asObservable();
  }
  get PredictionsAreLoadingObs() {
    return this._PredictionsAreLoadingObs.asObservable();
  }

  // Called by app.component to request the predictions on the map for the next hour
  public updatePredictionsForNextHour() {
    this._autoFetchPredictionsNextHr.next(true);
  }


  public RemovePredictionDrawing() {
    this.markerServ.removeAllPredIconsGrids();
  }

  InitialPredictionsLoadedPromise(): Promise<void> {
    return new Promise((res) => {
      if (this._InitialPredictionsLoadedStatic === true) {
        return res();
      }
      const sub = this.InitialPredictionsLoadedObs.subscribe((isLoaded) => {
        if (isLoaded === true) {
          sub.unsubscribe();
          this._InitialPredictionsLoadedStatic = true;
          return res();
        }
      });
    });
  }

  get shouldRunInitialPredictionObs() {
    return this._shouldRunInitialPrediction.asObservable();
  }

  public startInitialPrediction(): Promise<void> {
    return new Promise((res) => {
      const sub = this.facilServ.currentSelectedFacilityIDObs.subscribe((id) => {
        if (id) {
          this._shouldRunInitialPrediction.next(true);
          this.InitialPredictionsLoadedPromise().then(() => {
            if (sub) {
              sub.unsubscribe();
            }
            return res();
          });
        }
      });
    });
  }

  // Main prediction function
  // hideAni = true hides the loader animation
  async updatePredictionData(selectedHour: number = null, hideAni = false) {
    const loadingObsMsg = hideAni === false ? 'true_ani' : 'true_no_ani';
    this._PredictionsAreLoadingObs.next(loadingObsMsg);

    if (selectedHour !== null) {
      this.lastSelectedPredHour = selectedHour;
    }

    if (this.lastSelectedPredHour === null) {
      console.log('Error, no prediction data filter passed and no previous one stored');
      this._PredictionsAreLoadingObs.next('false');
      return;
    }

    let result = null;
    await this.facilServ.facilityIsSelectedPromise();
    if (this.facilServ.currentSelectedFacilityID) {
      const predDateTime = await this.convertToSelectedFacilityTimeUTC(this.lastSelectedPredHour);
      result = await this.bigQueryServ.getPredictionData(predDateTime, this.facilServ.currentSelectedFacilityID);
    }
    if (result) {
      this.updateInternalGridPredictionData(result);
      this.renderPredictionResults();
      this.markerServ.createViewLayerGrids(this._GridAndPredictionsArray);
    } else {
      if (this.facilServ.facilityIsSelected) {
        console.log('No prediction data for selected facility');
      }

      this.markerServ.createViewLayerGrids([]);
    }

    if (this._InitialPredictionsLoadedStatic === false) {
      this._InitialPredictionsLoadedObs.next(true);
      this._InitialPredictionsLoadedStatic = true;
    }
    this._PredictionsAreLoadingObs.next('false');
  }

  // Takes a selected hour and converts it to the local time of the currently selected facility and returns in UTC
  convertToSelectedFacilityTimeUTC = (selectedHour: number): string => {
    // Converts local to UTC and formats string for server
    const timeZ = this.facilServ.currentSelectedFacility['sqlData'].timezone;
    const predDateTime = new Date();
    const newMoment = [predDateTime.getFullYear(), predDateTime.getMonth(), predDateTime.getDate(), selectedHour];
    // Convert our time to the facilities local time
    const facLocalTime = moment.tz(newMoment, timeZ);

    // Convert facility local time to UTC
    const utcTimeStamp = facLocalTime.utc().format();
    return utcTimeStamp;

  }

  updateInternalGridPredictionData(data: Array<Object>): void {
    this._GridAndPredictionsHash = {};
    this._GridAndPredictionsArray = [];
    data.forEach((rData) => {
      const rowData = rData as any;
      const gridBoundsOb = this.computeGridBounds(rowData.geography);
      const centerPoint = this.computeGridCenterPoint(gridBoundsOb);
      const gridKey = rowData['id'];
      const newGridObj = {
        id: gridKey,
        centerLat: centerPoint['lat'],
        centerLng: centerPoint['lng'],
        gridBounds: gridBoundsOb,
        serviceCalls: parseFloat(rowData.light_calls),
        towCalls: parseFloat(rowData.tow_calls)
      };
      this._GridAndPredictionsHash[gridKey] = newGridObj;
      this._GridAndPredictionsArray.push(newGridObj);
    });
  }

  computeGridCenterPoint(gridBounds: object): Object {
    const result = {};
    result['lng'] = gridBounds['maxLng'] - (gridBounds['maxLng'] - gridBounds['minLng']) / 2;
    result['lat'] = gridBounds['maxLat'] - (gridBounds['maxLat'] - gridBounds['minLat']) / 2;

    return result;
  }

  computeGridBounds(geoStr: string): Object {
    const cArray = geoStr.replace(/[A-Z()]/g, '').split(/[\s,]+/);

    return {
      minLat: parseFloat(cArray[3]),
      maxLat: parseFloat(cArray[1]),
      minLng: parseFloat(cArray[0]),
      maxLng: parseFloat(cArray[4])
    };
  }

  async renderPredictionResults() {
    // await this.mapService.getMapRefLoadPromise();
    this.RemovePredictionDrawing();
    const predIPadding = this._predIconPadding;
    this._GridAndPredictionsArray.forEach((predEl) => {
      if (predEl.towCalls + predEl.serviceCalls > 0) {
        const towLocation = new google.maps.LatLng(predEl.centerLat, predEl.centerLng + predIPadding);
        const servLocation = new google.maps.LatLng(predEl.centerLat, predEl.centerLng - predIPadding);
        this.markerServ.createNewPredictionNumberIcon('TOW', towLocation, predEl.towCalls, predEl.id);
        this.markerServ.createNewPredictionNumberIcon('SERVICE', servLocation, predEl.serviceCalls, predEl.id);
        this.markerServ.createPredictionGrid(predEl.gridBounds);
      }
    });
  }

}
