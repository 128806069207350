import { Component } from '@angular/core';
import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  Facility = SettingsPanelState.Facility;
  Calls = SettingsPanelState.Calls;
  Fleet = SettingsPanelState.Fleet;
  Predictions = SettingsPanelState.Predicitions;
  Legend = SettingsPanelState.Legend;
  IconLayers = SettingsPanelState.IconLayers;
  Logo = SettingsPanelState.Logo;
  constructor() { }
}
