import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appConfig } from 'src/app/app-config-defaults';
import { environment } from 'src/environments/environment';
import * as qs from 'qs';


@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {
  private oauthToken;

  constructor(
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) { }

  //Use for anything that is not proxied through APIgee
  public async sendWebRequest(qType: requestTypes, url: string, reqBody = null,): Promise<any> {
    if (qType === requestTypes.get) {
      return new Promise((res) => {
        const sub = this.http.get(url).subscribe(result => {
          if (sub) {
            sub.unsubscribe();
          }
          return res(result);
        });
      });
    } else if (qType === requestTypes.post) {
      return new Promise((res) => {
        const sub = this.http.post(url, reqBody).subscribe(result => {
          if (sub) {
            sub.unsubscribe();
          }
          return res(result);
        });
      });
    }
  }

  //Use for requests to APIgee endpoints
  public async sendRequest(qType: requestTypes, endPoint: string, reqBody = null, reqParams = null, skipToken = false): Promise<any> {
    let fbToken = '';
    let oauthToken = null;
    endPoint = environment.backEndBaseUrl + endPoint;
    if (skipToken === false) {
      fbToken = await this.afAuth.auth.currentUser.getIdToken(false);
      oauthToken = await this.getOAuthToken(fbToken);
    }

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Authorization', `Bearer ${oauthToken}`);

    const params = new HttpParams();
    if (reqParams) {
      Object.keys(reqParams).forEach(key => {
        params.set(key, reqParams[key]);
      });
    }

    const httpOptions = {
      headers: header,
      params: params
    };
    if (qType === requestTypes.get) {
      return new Promise((res) => {
        const sub = this.http.get(endPoint, httpOptions).subscribe(result => {
          if (sub) {
            sub.unsubscribe();
          }
          return res(result);
        });
      });
    } else if (qType === requestTypes.post) {
      return new Promise((res) => {
        const sub = this.http.post(endPoint, reqBody, httpOptions).subscribe(result => {
          if (sub) {
            sub.unsubscribe();
          }
          return res(result);
        });
      });
    }
  }

  async getOAuthToken(fbToken) {
    if (this.oauthToken && this.checkExpire(this.oauthToken)) {
      return this.oauthToken.access_token;
    }
    let tokenObj = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'X-FirebaseAuth-Token': fbToken,
        'X-AppName': appConfig.appName,
      })
    };

    const body = qs.stringify({ grant_type: 'client_credentials' });

    try {
      tokenObj = await new Promise((res) => {
        const sub = this.http.post(environment.oauthUrl, body, httpOptions).subscribe(result => {
          if (sub) {
            sub.unsubscribe();
          }
          return res(result);
        });
      });
    } catch (err) {
      console.log(err);
    }
    this.oauthToken = tokenObj;
    return tokenObj.access_token;
  }

  // returns true if it has not expired
  checkExpire(tokenObj) {
    // Calc time point that token expired, subtract 60 to leave room for error
    // tslint:disable-next-line:radix
    const expireTime = parseInt(tokenObj.issued_at) + (parseInt(tokenObj.expires_in) - 60) * 1000;
    return Date.now() < expireTime;
  }



}

export const enum requestTypes {
  post,
  get
}

