import { Component } from '@angular/core';
import { CallsMenuService } from 'src/services/calls-menu/calls-menu.service';
import { ServiceStatus } from 'src/models/map-marker/icon-status/service-status';
import { MapMarkerService } from './../../services/map-marker/map-marker.service';


@Component({
  selector: 'app-service-call-menu',
  templateUrl: './service-call-menu.component.html',
  styleUrls: ['./service-call-menu.component.scss']
})
export class CallsMenuComponent {

  serviceCallInfo: Array<object>;
  calcElapsedDelaySec = 60;

  headerTitles = {
    status: 'Status',
    serviceCallId: 'Service Call ID',
    callDate: 'Call Received Date and Time',
    timeElapsed: 'Time Elapsed',
    memberId: 'Member ID',
    yearMakeModel: 'Year/Make/Model',
    breakDownLocationAddress: 'Breakdown Address',
    towDestination: 'Tow Destination Address',
    eta: 'ETA',
    serviceTypeVerbiage: 'Service Type',
    headerRow: 'true'
  };
  showFacSelectMsg = true;
  hideScroll = true;
  elapseTInt: any;

  constructor(private callsMenuServ: CallsMenuService, private mapMarkerServ: MapMarkerService) {
    this.callsMenuServ.serviceCallInfo.subscribe((calls) => {
      this.handleCallInfoUpdate(calls);
    });
    if (this.elapseTInt) {
      clearInterval(this.elapseTInt);
    }
    this.elapseTInt = setInterval(() => { this.calcElapsedTimeRoutine(); }, this.calcElapsedDelaySec * 1000);
  }

  private sortCallArray(cInfo: Array<object>): Array<object> {
    if (cInfo.length < 1) {
      return [];
    }

    let orderedArray;
    const unassaignedA = [];
    const enrouteA = [];
    const onSiteA = [];
    const closedA = [];

    cInfo.forEach((call) => {
      switch ((call as any).status) {
        case ServiceStatus.RequestedNoTruck:
          unassaignedA.push(call);
          break;
        case ServiceStatus.RequestedTruckAssigned:
          enrouteA.push(call);
          break;
        case ServiceStatus.OnSiteTowing:
          onSiteA.push(call);
          break;
        case ServiceStatus.Closed:
        case ServiceStatus.Killed:
          closedA.push(call);
          break;
        default:
          unassaignedA.push(call);
          break;
      }
    });


    orderedArray = onSiteA.concat(enrouteA, unassaignedA, closedA);
    orderedArray.unshift(this.headerTitles);
    return orderedArray;
  }


  private handleCallInfoUpdate(cInfo) {
    const orderedCInfo = this.sortCallArray(cInfo);
    this.serviceCallInfo = orderedCInfo;
    this.showFacSelectMsg = this.serviceCallInfo.length < 1 ? true : false;
    this.hideScroll = this.serviceCallInfo.length <= 11 ? true : false;
  }

  calcElapsedTimeRoutine() {
    this.serviceCallInfo.forEach((call) => {
      if (call['headerRow'] !== 'true') {
        call['timeElapsed'] = this.mapMarkerServ.getServiceIconById(call['serviceCallId']).timeElapsedStr;
      }
    });
  }

}
