import { AuthService } from './../../services/auth/auth.service';
import { SettingsPanelAnimationService } from './../../services/settings-panel-animation/settings-panel-animation.service';
import { Component, OnInit } from '@angular/core';
import { IconLayersService, IconLayerTypes } from 'src/services/icon-layers/icon-layers.service';
import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';

@Component({
  selector: 'app-icon-layers',
  templateUrl: './icon-layers.component.html',
  styleUrls: ['./icon-layers.component.scss']
})
export class IconLayersComponent implements OnInit {
  _iconBaseDir = 'assets/imgs/viewLayer-icons/';

  iconLayers = [
    { name: 'Branches', iconType: IconLayerTypes.Branch, imgUrl: `${this._iconBaseDir}branch-icon.svg` },
    { name: 'Fleet Facilities', iconType: IconLayerTypes.FleetFacility, imgUrl: `${this._iconBaseDir}facility-fleet-icon.svg` },
    { name: 'Service Providers', iconType: IconLayerTypes.ServiceFacility, imgUrl: `${this._iconBaseDir}facility-SP-icon.svg` },
    { name: 'Wait Locations', iconType: IconLayerTypes.WaitLocations, imgUrl: `${this._iconBaseDir}hotSpot-icon.svg` },
    { name: 'Air Quality', iconType: IconLayerTypes.AirQuality, imgUrl: `${this._iconBaseDir}airQuality-icon.svg` },
    { name: 'Active Fires', iconType: IconLayerTypes.Fires, imgUrl: `${this._iconBaseDir}fire-icon.svg` },
    { name: 'Counties', iconType: IconLayerTypes.Counties, imgUrl: `${this._iconBaseDir}county-icon.svg` },
    { name: 'Boundaries - Tow', iconType: IconLayerTypes.ShapeFileTow, imgUrl: `${this._iconBaseDir}shapeFileTow-icon.svg` },
    { name: 'Boundaries - Light', iconType: IconLayerTypes.ShapeFileLight, imgUrl: `${this._iconBaseDir}shapeFileLight-icon.svg` },
    { name: 'Boundaries - Battery', iconType: IconLayerTypes.ShapeFileBattery, imgUrl: `${this._iconBaseDir}shapeFileBattery-icon.svg` },
    { name: 'Prediction Grids', iconType: IconLayerTypes.Boundries, imgUrl: `${this._iconBaseDir}boundry-icon.svg` },
    { name: 'Show Closed Calls', iconType: IconLayerTypes.ClosedCalls, imgUrl: `${this._iconBaseDir}person-black-icon.svg` },
    { name: 'Show OV Trucks', iconType: IconLayerTypes.ShowOV, imgUrl: `${this._iconBaseDir}hideOV-icon.svg` },
  ];

  constructor(
    private iconLayServ: IconLayersService,
    private authServ: AuthService,
    private navAni: SettingsPanelAnimationService) { }

  ngOnInit() {
  }

  handleSelect(isChecked, iconType) {
    this.iconLayServ.iconVisabilityStateChange(iconType, isChecked);
  }

  handleSignOut() {
    this.navAni.updateSettingsPanelState(SettingsPanelState.Closed);
    this.authServ.signOut();
  }

}
