import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable, Inject, Component } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import * as endPoints from '../httpRequests/apiEndpoints';
import { HttpRequestsService, requestTypes } from '../httpRequests/http-requests.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { first } from 'rxjs/operators';
import { users } from '../firebase-data/fbCollections';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isSignedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _userDocObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _isSignedInStatic = false;
  private _userDoc = null;
  private _signinFadeOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private AppId = 'com.goaaa.kassandra';
  private noAccessMsg = 'It looks like your account does not currently have access to Kassandra. Please contact IT for assistance.';

  constructor(private afAuth: AngularFireAuth,
    private httpServ: HttpRequestsService,
    private afs: AngularFirestore,
    public errorDialog: MatDialog
  ) {

    this.checkFirstLogin();
  }

  get userDocObs() {
    return this._userDocObs.asObservable();
  }

  // Use to get the user's firebase document
  userInfo(): Promise<object> {
    return new Promise(res => {
      if (this._userDoc !== null) {
        return res(this._userDoc);
      } else {
        const sub = this._userDocObs.subscribe(user => {
          if (user) {
            if (sub) {
              sub.unsubscribe();
            }
            this._userDoc = user;
            return res(user);
          }
        });
      }
    });
  }

  private async checkFirstLogin() {
    const savedSession = await this.firebaseIsLoggedIn();
    if (savedSession) {
      this.userAuthCheck();
    } else {
      this.signOut();
    }
  }

  private firebaseIsLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  get isSignedInObs() {
    return this._isSignedIn.asObservable();
  }

  public isSignedInPromise() {
    return new Promise(res => {
      if (this._isSignedInStatic === true) {
        return res();
      } else {
        const sub = this.isSignedInObs.subscribe(isSignedIn => {
          if (isSignedIn === true) {
            if (sub) {
              sub.unsubscribe();
            }
            this._isSignedInStatic = true;
            return res();
          }
        });
      }
    });
  }

  get signInFadeOut() {
    return this._signinFadeOut.asObservable();
  }

  setSignInState(newState: boolean) {
    this._signinFadeOut.next(newState);
    this._isSignedIn.next(newState);
  }

  public signOut() {
    this.setSignInState(false);
    this.afAuth.auth.signOut();
  }

  openDialog(msg: string): void {
    this._signinFadeOut.next(false);
    this.errorDialog.open(AuthDialogComponent, {
      width: '750px',
      data: { msg: msg }
    });
  }

  async loginWithEmail(email: string, password: string) {
    this._signinFadeOut.next(true);
    try {
      await this.afAuth.auth.signInWithEmailAndPassword(email, password);
      this.userAuthCheck();
    } catch (err) {
      let msg = 'We are sorry but there was a problem logging you in.';
      if (err && err.hasOwnProperty('code')) {
        switch (err.code) {
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            msg = 'We could not find an account for this email address.'
              + ' If you are a new user, please click "Create new account".';
            break;
          case 'auth/invalid-email':
          case 'auth/wrong-password':
            msg = 'Your email or password is incorrect, please try again';
            break;
        }
      }
      this.openDialog(msg);
      console.log(err);
    }
  }

  async loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    try {
      await this.afAuth.auth.signInWithPopup(provider);
      this.userAuthCheck();
    } catch (err) {
      console.log(err);
    }

  }

  async userAuthCheck() {
    this._signinFadeOut.next(true);
    const result = await this.afs.collection(users).doc(this.afAuth.auth.currentUser.uid).get().toPromise();
    const uDoc = result.data();
    const errorStatus = this.checkUserAppAccess(uDoc);
    if (errorStatus) {
      this.openDialog(errorStatus);
      this.signOut();
    } else {
      this.signInSuccess(uDoc);
    }
  }

  private checkUserAppAccess(user: any): string {

    if (user && Object.prototype.hasOwnProperty.call(user, 'apps') && user.apps.indexOf(this.AppId) !== -1) {
      return null;
    } else {
      return this.noAccessMsg;
    }

  }

  async signInSuccess(userDoc: any) {
    this._userDocObs.next(userDoc);
    this.setSignInState(true);
  }

  public async createNewEmailUser(email: string, password: string) {
    this._signinFadeOut.next(true);
    let msg = null;
    try {
      await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      msg = 'Your account has been succuessfully created! If your account has been authorized you will now be able to login.';

    } catch (err) {
      msg = this.acErrorMsg(err);
    }
    this.openDialog(msg);
    this.signOut();
  }

  acErrorMsg(err) {
    let msg = null;
    switch (err.code) {
      case 'auth/email-already-in-use':
        msg = 'The email address you entered is already registered.'
          + ' If your account has been authorized to use this application you can sign in';
        break;
      case 'auth/invalid-email':
        msg = 'Your email address is not valid, make sure it has been typed correctly';
        break;
      case 'auth/weak-password':
        msg = 'Sorry your password is too weak. Please make sure it is at least 6 characters long';
        break;
      default:
        msg = 'We are sorry but there was a problem creating your account. Please contact IT for assistance';
    }
    return msg;
  }

  public async passwordReset(email: string) {
    try {
      await this.afAuth.auth.sendPasswordResetEmail(email);
      const msg = 'To verify your password change request we have sent you an email.'
        + ' Please follow the instructions in the email to reset your password.';
      this.openDialog(msg);
      return 'success';
    } catch (err) {
      console.log(err);
      const msg = 'There was a problem with your password change request. Please verify your email address and try again.';
      this.openDialog(msg);
      return 'error';
    }
  }
}

@Component({
  // tslint:disable-next-line
  selector: 'Auth-dialog-component',
  templateUrl: './authDialog.html',
})
export class AuthDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onCloseClick(): void {
    this.dialogRef.close();
  }
}
