import { GenericIcon } from './generic-icon.model';

export class WaitLocation extends GenericIcon {
  private _WLIconSize = 23;
  iconFile = `${this.iconBaseDir}/hotSpot.svg`;
  address: string;
  facID: string;
  name: string;

  constructor(currMap: google.maps.Map,
    currLoc: google.maps.LatLng,
    address: string,
    facID: string,
    name: string) {
    super(currMap);
    this._currentLocation = currLoc;
    this.address = address;
    this.facID = facID;
    this.name = name;
    this.createMarker();
  }

  createMarker() {
    const newIcon = {
      url: this.iconFile,
      scaledSize: new google.maps.Size(this._WLIconSize, this._WLIconSize)
    };
    const markerOptions = { position: this._currentLocation };
    markerOptions['icon'] = newIcon;
    this._marker = new google.maps.Marker(markerOptions);
    this.setMarkerInfoWindow();
    this.updateInfoWindow(this.generateFacilityInfoStr());
    // fix for info window flicker
    this._currentInfoWindow.set('pixelOffset', new google.maps.Size(0, -3));
  }

  generateFacilityInfoStr(): string {
    return `
    <div style="text-align:left; padding: 5px;">
      <div style="text-align: left;
      color: navy;
      font-size: 15px;
      margin-bottom: 5px;
      font-weight: 500;">Wait Location</div>
      <div>${this.name}</div>
      <div>${this.address}</div>
      <div>Facility: ${this.facID}</div>
    </div>
    `;
  }
  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }

}

