import { forEach } from '@angular/router/src/utils/collection';
import { IconLayerTypes } from './../../services/icon-layers/icon-layers.service';

export class CrvShapeFileGrid {
  isVisable: boolean;
  private currentMap: google.maps.Map;
  // private labelSize = 160;
  // private labelUrl = 'assets/imgs/county-label.svg';
  // private labelFontSize = '18px';
  private shapeFilePolyArray;
  private polyDefaultOpacity = 0;
  private polyDefaultStrokeOpacity = 1;
  private polyDefaultStrokeWeight = 2;
  private polySettings: Object;
  private shapeFileInfo: Object;
  // private polyHoverOpacity = 0.2;
  // private countyName: string;
  // private labelZindex = 20;

  // private labelIcon = {
  //   url: this.labelUrl,
  //   scaledSize: new google.maps.Size(this.labelSize, this.labelSize),
  //   anchor: new google.maps.Point(this.labelSize / 2, this.labelSize / 2)
  // };

  private TowSettings = {
    polyFillColor: '#ff5e5e',
    polyStrokeColor: '#ff5e5e',
  };

  private LightSettings = {
    polyFillColor: '#3681f9',
    polyStrokeColor: '#3681f9',
  };

  private BatterySettings = {
    polyFillColor: '#c69323',
    polyStrokeColor: '#c69323',
  };


  constructor(currMap: google.maps.Map, shapeFileInfo: Object) {

    this.currentMap = currMap;
    this.shapeFileInfo = shapeFileInfo;
    switch (shapeFileInfo['shapeType']) {
      case IconLayerTypes.ShapeFileBattery:
        this.polySettings = this.BatterySettings;
        break;
      case IconLayerTypes.ShapeFileTow:
        this.polySettings = this.TowSettings;
        break;
      case IconLayerTypes.ShapeFileLight:
        this.polySettings = this.LightSettings;
        break;
    }
    this.createShapeGrid();
  }

  createShapeGrid() {
    const shapeArray = [];

    this.shapeFileInfo['coords'].forEach((sCoords) => {
      const shapeFilePoly = new google.maps.Polygon({
        paths: sCoords,
        strokeColor: this.polySettings['polyStrokeColor'],
        strokeOpacity: this.polyDefaultStrokeOpacity,
        strokeWeight: this.polyDefaultStrokeWeight,
        fillColor: this.polySettings['polyFillColor'],
        fillOpacity: this.polyDefaultOpacity,
        map: null
      });
      shapeArray.push(shapeFilePoly);
    });
    this.shapeFilePolyArray = shapeArray;
  }
  // this.labelMarker = new google.maps.Marker({
  //   position: this.findCenterPoly(this.coordinates),
  //   map: null,
  //   zIndex: this.labelZindex,
  //   icon: this.labelIcon,
  //   label: {
  //     color: 'white',
  //     fontWeight: 'bold',
  //     fontSize: this.labelFontSize,
  //     text: this.countyName,
  //   }
  // });

  setVisability(isVisable: boolean): void {
    switch (isVisable) {
      case true:
        this.shapeFilePolyArray.forEach((el) => {
          el.setMap(this.currentMap);
        });
        // this.addListeners();
        break;
      case false:
        this.shapeFilePolyArray.forEach((el) => {
          el.setMap(null);
        });
      // this.removeListeners();
    }
  }

  // addListeners() {
  //   this.shapeFilePolyArray.forEach((el) => {
  //     google.maps.event.addListener(el, 'mouseover', () => {
  //       // this.labelMarker.setMap(this.currentMap);
  //       el.setOptions({ 'fillOpacity': this.polyHoverOpacity });
  //     });
  //     google.maps.event.addListener(el, 'mouseout', () => {
  //       // this.labelMarker.setMap(null);
  //       el.setOptions({ 'fillOpacity': this.polyDefaultOpacity });
  //     });
  //   });
  // }

  removeListeners() {
    this.shapeFilePolyArray.forEach((el) => {
      google.maps.event.clearListeners(el, 'mouseover');
      google.maps.event.clearListeners(el, 'mouseout');
    });
  }

  // findCenterPoly(arr: Array<Object>) {
  //   let x1 = arr[0]['lat'];
  //   let x2 = arr[0]['lat'];
  //   let y1 = arr[0]['lng'];
  //   let y2 = arr[0]['lng'];

  //   arr.forEach((el) => {
  //     if (el['lat'] < x1) {
  //       x1 = el['lat'];
  //     }
  //     if (el['lat'] > x2) {
  //       x2 = el['lat'];
  //     }
  //     if (el['lng'] < y1) {
  //       y1 = el['lng'];
  //     }
  //     if (el['lng'] > y2) {
  //       y2 = el['lng'];
  //     }
  //   });

  //   const centerX = x1 + ((x2 - x1) / 2);
  //   const centerY = y1 + ((y2 - y1) / 2);
  //   return { lat: centerX, lng: centerY };
  // }

}

