import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
declare var gapi: any;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  public emailForm: FormGroup;
  public acForm: FormGroup;
  public passResetForm: FormGroup;

  showEmail = false;
  showAccCreate = false;
  showPassReset = false;
  hide = true;
  fadeOut = true;

  constructor(private authServ: AuthService) {
    this.authServ.signInFadeOut.subscribe(fadeState => {
      this.fadeOut = !fadeState;
    });
  }


  ngOnInit() {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.acForm = new FormGroup({
      ACemail: new FormControl('', [Validators.required, Validators.email]),
      ACpassword: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.passResetForm = new FormGroup({
      passResetEmail: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  googleSignInClick() {
    this.authServ.loginWithGoogle();
    this.closeAllForms();
  }

  emailSignInClick() {
    this.closeAllForms();
    this.showEmail = true;
  }

  accountCreateClick() {
    this.closeAllForms();
    this.showAccCreate = true;
  }

  passResetClick() {
    this.closeAllForms();
    this.showPassReset = true;
  }

  closeAllForms() {
    this.showAccCreate = false;
    this.showEmail = false;
    this.showPassReset = false;
  }

  getErrorMsg(controlName: string) {
    let msg = null;
    switch (controlName) {
      case 'email':
        msg = this.emailForm.controls[controlName].hasError('required') ? 'You must enter a value' :
          this.emailForm.controls[controlName].hasError('email') ? 'Not a valid email' : null;
        break;
      case 'password':
        msg = this.emailForm.controls[controlName].hasError('required') ? 'You must enter a value' :
          this.emailForm.controls[controlName].hasError('minLength') ?
            'Password must be at least 6 characters' : null;
        break;
      case 'ACemail':
        msg = this.acForm.controls[controlName].hasError('required') ? 'You must enter a value' :
          this.acForm.controls[controlName].hasError('email') ? 'Not a valid email' : null;
        break;
      case 'ACpassword':
        msg = this.acForm.controls[controlName].hasError('required') ? 'You must enter a value' :
          this.acForm.controls[controlName].hasError('minLength') ?
            'Password must be at least 6 characters' : null;
        break;
      case 'PassResetEmail':
        msg = this.passResetForm.controls[controlName].hasError('required') ? 'You must enter a value' :
          this.passResetForm.controls[controlName].hasError('email') ? 'Not a valid email' : null;
        break;

    }
    return msg;
  }

  submitEmailSignIn() {
    const email = this.emailForm.get('email').value;
    const password = this.emailForm.get('password').value;
    this.authServ.loginWithEmail(email, password);
  }

  submitUserCreation() {
    const email = this.acForm.get('ACemail').value;
    const password = this.acForm.get('ACpassword').value;
    this.authServ.createNewEmailUser(email, password);
    this.showAccCreate = false;
  }

  async submitPassReset() {
    const email = this.passResetForm.get('passResetEmail').value;
    const result = await this.authServ.passwordReset(email);
    if (result === 'success') {
      this.showPassReset = false;
    } else {
      this.showPassReset = true;
    }

  }

}
