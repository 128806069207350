export const enum TruckStatus {
  DriverOutofVehicle = 'Driver out of vehicle',
  AssignedToMember = 'Assigned to member',
  OnSiteTowing = 'Onsite/Towing',
  NotAssigned = 'Not assigned'
}

export const TruckColors = {
  'Driver out of vehicle': '#ff0000ff',
  'Assigned to member': '#d58a3e',
  'Onsite/Towing': '#0000de',
  'Not assigned': '#777777'
};
