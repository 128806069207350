export const enum ServiceStatus {
  RequestedNoTruck = 'Requested - No Truck',
  RequestedTruckAssigned = 'Requested - Truck Assigned',
  OnSiteTowing = 'Onsite/Towing',
  Closed = 'Closed',
  Killed = 'Killed'
}

export const ServiceColors = {
  'Requested - No Truck': '#e74c3c',
  'Requested - Truck Assigned': '#d58a3e',
  'Onsite/Towing': '#0000de',
  'Closed': '#000000',
  'Killed': '#000000'
};
