import { PredictionsService } from './../../services/predictions/predictions.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PreferenceDefaultServiceService } from './../../services/preference-default-service/preference-default-service.service';
import { FacilityService } from './../../services/facility-service/facility.service';

(Date as any).prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
};

@Component({
  selector: 'app-predictions',
  templateUrl: './predictions.component.html',
  styleUrls: ['./predictions.component.scss']
})
export class PredictionsComponent implements OnInit {

  predHourLocal: number; // desired prediction hour in local time, set both by user input and programatically
  predAutoRefresh: boolean; // checkbox input
  initialPredLoaded = false;

  // for bigQuery time values need to be 0-23 (12am to 11pm)
  timeVals = [
    { value: 0, viewValue: '12 am' },
    { value: 1, viewValue: '1 am' },
    { value: 2, viewValue: '2 am' },
    { value: 3, viewValue: '3 am' },
    { value: 4, viewValue: '4 am' },
    { value: 5, viewValue: '5 am' },
    { value: 6, viewValue: '6 am' },
    { value: 7, viewValue: '7 am' },
    { value: 8, viewValue: '8 am' },
    { value: 9, viewValue: '9 am' },
    { value: 10, viewValue: '10 am' },
    { value: 11, viewValue: '11 am' },
    { value: 12, viewValue: '12 pm' },
    { value: 13, viewValue: '1 pm' },
    { value: 14, viewValue: '2 pm' },
    { value: 15, viewValue: '3 pm' },
    { value: 16, viewValue: '4 pm' },
    { value: 17, viewValue: '5 pm' },
    { value: 18, viewValue: '6 pm' },
    { value: 19, viewValue: '7 pm' },
    { value: 20, viewValue: '8 pm' },
    { value: 21, viewValue: '9 pm' },
    { value: 22, viewValue: '10 pm' },
    { value: 23, viewValue: '11 pm' }
  ];

  constructor(
    public errorDialog: MatDialog,
    private predServ: PredictionsService,
    private prefServ: PreferenceDefaultServiceService,
    private facServ: FacilityService) {

  }

  openErrorDialog(msg: string): void {
    this.errorDialog.open(ErrorDialogComponent, {
      width: '450px',
      data: { msg: msg }
    });
  }

  ngOnInit() {
    const autoR = this.prefServ.loadAutoPredRefreshCookie();
    this.predAutoRefresh = autoR === 'false' ? false : true;
    this.storeRefreshCookie();
    this.updatePredInputToNextHour();
    const sub = this.predServ.shouldRunInitialPredictionObs.subscribe(async (shouldRun) => {
      if (shouldRun === true) {
        // Updating predictions to next hour
        this.updatePredInputToNextHour();
        await this.submitPredictQuery(false, false);
        sub.unsubscribe();
      }
    });
    this.predServ.AutoFetchPredictionsNextHrObs.subscribe(async (msg) => {
      if (msg === true && this.predAutoRefresh === true) {
        this.updatePredInputToNextHour();
        await this.submitPredictQuery(true, false);
      }
    });
  }

  // Sets the select prediction time to be for next hour
  updatePredInputToNextHour() {
    const date = new Date();
    (date as any).addHours(1);
    // sets to current local time
    this.predHourLocal = date.getHours();
  }

  // hideAni = true, hides loader
  // enableErrMsg = true, used by manual prediction update if user submits without facility
  async submitPredictQuery(hideAni: boolean, enableErrMsg: boolean) {
    if (enableErrMsg && this.facServ.facilityIsSelected === false) {
      this.openErrorDialog('Please select a facility first to view predictions.');
      return;
    }
    await this.predServ.updatePredictionData(this.predHourLocal, hideAni);
    if (this.initialPredLoaded === false) {
      this.initialPredLoaded = true;
    }
  }

  removeClick() {
    this.predServ.RemovePredictionDrawing();
  }

  storeRefreshCookie() {
    this.prefServ.storeAutoPredRefreshCookie(this.predAutoRefresh);
  }
}

@Component({
  // tslint:disable-next-line
  selector: 'error-dialog-component',
  templateUrl: './errorDialog.html',
})
export class ErrorDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
