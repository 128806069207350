export const environment = {
  firebase: {
    apiKey: 'AIzaSyAtrEJ_Xv0xom1FKQBQRrG6MVdCT0nl4jc',
    authDomain: 'aaa-mwg-trucktrackerdev.firebaseapp.com',
    databaseURL: 'https://aaa-mwg-trucktrackerdev.firebaseio.com',
    projectId: 'aaa-mwg-trucktrackerdev',
    storageBucket: 'gs://aaa-mwg-trucktrackerdev.appspot.com',
    messagingSenderId: '714570740557'
  },
  production: false,
  name: 'dev',
  backEndBaseUrl: 'https://devapi.goaaa.com/v1/kassandra',
  oauthUrl: 'https://devapi.goaaa.com/v1/oauth2',
  mapK: 'AIzaSyCNbAh2RBJFlM3fPADTlc5NTkXmJ7KbnjY'
};
