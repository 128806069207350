import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SettingsPanelAnimationService {
  private _settingsPanelState: BehaviorSubject<SettingsPanelState> = new BehaviorSubject<SettingsPanelState>(SettingsPanelState.Closed);

  get currentSettingsPanelState() {
    return this._settingsPanelState.getValue();
  }

  get settingsPanelStateListener() {
    return this._settingsPanelState.asObservable();
  }
  updateSettingsPanelState(settingState: SettingsPanelState) {
    this._settingsPanelState.next(settingState);
  }
  constructor() { }
}
