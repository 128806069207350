import {Provider, Injectable} from '@angular/core';

@Injectable()
export class WindowRef {
  getNativeWindow(): any { return window; }
}

@Injectable()
export class DocumentRef {
  getNativeDocument(): any { return document; }
}

export const BROWSER_GLOBALS_PROVIDERS: Provider[] = [WindowRef, DocumentRef];
