declare var require: any;

import { CountyGrid } from './../../models/map-marker/county-grid.model';
const countyBoundriesJSON = require('./../../assets/json/countyBoundries.json');
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountyGridsService {

  constructor() { }

  loadCountyGrids(currentMap: google.maps.Map): Array<CountyGrid> {
    const countArray = [];
    countyBoundriesJSON.features.forEach((cnty) => {
      const coords = cnty.geometry.coordinates[0];
      const polyArray = [];
      if (coords[0].length > 2) {
        (cnty.geometry.coordinates as any).forEach((subC) => {
          const latlngs = (subC[0] as any).map(el => {
            return { lat: el[1] || null, lng: el[0] || null };
          });
          polyArray.push(latlngs);
        });
      } else {
        const latlngs = (coords as any).map(el => {
          return { lat: el[1] || null, lng: el[0] || null };
        });
        polyArray.push(latlngs);
      }

      polyArray.forEach((countyCoords) => {
        const newC = new CountyGrid(currentMap, countyCoords, cnty.properties.NAME);
        countArray.push(newC);
      });
    });
    return countArray;
  }

}
