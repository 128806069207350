import { GenericIcon } from './generic-icon.model';
import { TruckStatus, TruckColors } from './icon-status/truck-status';
declare let google: any;

export class TruckIcon extends GenericIcon {
  private _currentStatus: TruckStatus;
  private _truckStatusSnapShot: any = null;
  private _serviceCallSnapShot: any = null;
  private _truckIconSize = 37;
  private _zIndex = 0;
  private _currOrientation = null;
  private _truckPlaceHolder: string;
  private _waitLocationPolyLine: google.maps.Polyline;
  private _currentWaitLocation: google.maps.LatLng;
  private _waitLocationColor = 'rgba(92,92,92,.7)';
  private _waitLocationArrowSize = 2;

  private iconFiles = new Map(
    [
      [TruckStatus.OnSiteTowing, `${this.iconBaseDir}/tow-blue.svg`],
      [TruckStatus.AssignedToMember, `${this.iconBaseDir}/tow-orange.svg`],
      [TruckStatus.NotAssigned, `${this.iconBaseDir}/tow-default.svg`],
      [TruckStatus.DriverOutofVehicle, `${this.iconBaseDir}/tow-red.svg`],
    ]
  );

  constructor(currMap: google.maps.Map, snapShot: Object, isVisible: boolean) {
    super(currMap);
    this.isVisable = isVisible;
    this._iconSize = this._truckIconSize;
    this._truckPlaceHolder = `${this._iconBaseDir}/truckPlaceHolder.svg`;
    this.updateTruckStatusSnapShot(snapShot);

  }

  get truckStatusSnapShot(): any {
    return this._truckStatusSnapShot;
  }
  get hasServiceCallSnapShot(): boolean {
    return this._serviceCallSnapShot !== null;
  }
  get currentStatus(): TruckStatus {
    return this._currentStatus;
  }

  get currentDeviceSerial(): string {
    if (this.truckStatusSnapShot !== null && this.truckStatusSnapShot.deviceSerial) {
      return this.truckStatusSnapShot.deviceSerial;
    } else {
      return null;
    }
  }

  // get info on our snapshot, note this could be different than the active service call on the truck snapshot
  get currentServiceCallSnapshotStatus(): string {

    return this._serviceCallSnapShot ? this._serviceCallSnapShot.currentStatus : null;
  }
  // get info on our snapshot, note this could be different than the active service call on the truck snapshot
  get currentServiceCallSnapshotId(): string {
    return this._serviceCallSnapShot ? this._serviceCallSnapShot.serviceCallId : null;
  }
  // get info about the most recent call from our current truck snapshot
  get truckActiveServiceCallId(): any {
    if (this.hasActiveServiceCall()) {
      return this.truckStatusSnapShot.activeServiceCalls[0];
    }
    return false;
  }

  set currentStatus(status: TruckStatus) {
    this._currentStatus = status;
  }

  hasActiveServiceCall(): boolean {
    if (this.truckStatusSnapShot && this.truckStatusSnapShot.activeServiceCalls && this.truckStatusSnapShot.activeServiceCalls.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get full_truck_id(): string {
    if (this.truckStatusSnapShot) {
      return `${this.truckStatusSnapShot.facilityId}-${this.truckStatusSnapShot.truckId}`;

    } else {
      return null;
    }
  }

  get facility_Id(): string {
    if (this.truckStatusSnapShot) {
      return this.truckStatusSnapShot.facilityId;
    } else {
      return null;
    }
  }

  // Set the correct icon given the current status, create a marker if it does not exit
  updateMarker(infoStr: string = null, bearing: number = null): void {
    if (this.hasMarker() !== true) {
      this.createMarker();

    }
    if (bearing) {
      this._currOrientation = bearing;
    }

    const x = Math.sin(-bearing * Math.PI / 180) * 0.5 + 0.5;
    const y = -(Math.cos(-bearing * Math.PI / 180) * 0.5 - 0.5);
    try {
      this._currentInfoWindow.set('pixelOffset', new google.maps.Size(x, y));

    } catch (err) {
    }

    if (this._currOrientation && this._currentLocation && this._marker['rotatedImage'].loaded) {
      (this._marker as any).setOptions(
        { icon: makeIcon(this._marker['rotatedImage'].rotate(this._currOrientation, this._currentStatus), this._iconSize) }
      );
    } else if (this._marker['rotatedImage'].loaded) {
      (this._marker as any).setOptions(
        { icon: makeIcon(this._marker['rotatedImage'].rotate(0, this._currentStatus), this._iconSize) }
      );
    }
    if (infoStr) {
      this.updateInfoWindow(infoStr);
    }
  }

  createMarker() {
    if (this._marker) {
      return;
    }
    const rotatedImage = new RotatedImage(
      {
        OnSiteTowingUrl: this.iconFiles.get(TruckStatus.OnSiteTowing),
        AssignedToMemberUrl: this.iconFiles.get(TruckStatus.AssignedToMember),
        NotAssignedUrl: this.iconFiles.get(TruckStatus.NotAssigned),
        DriverOutofVehicleUrl: this.iconFiles.get(TruckStatus.DriverOutofVehicle)
      });
    const newIcon = {
      url: this._truckPlaceHolder,
      anchor: new google.maps.Point(this.iconSize / 2, this._iconSize / 2)
    };
    let map = null;
    if (this.isVisable) {
      map = this._currentMap;
    }
    const markerOptions = {
      position: this._currentLocation,
      zIndex: this._zIndex,
      map: map
    };
    markerOptions['icon'] = newIcon;
    markerOptions['rotatedImage'] = rotatedImage;
    this._marker = new google.maps.Marker(markerOptions);
    rotatedImage.setLoadedCallback(this.loadedImageCallBack.bind(this));
    this.setMarkerInfoWindow();
  }

  loadedImageCallBack() {
    (this._marker as any).setOptions(
      {
        icon: makeIcon(this._marker['rotatedImage'].rotate(this._currOrientation, this._currentStatus), this._iconSize)
      });
    if (this.isVisable) {
      this.setVisability(true);
    }
  }

  public clearCurrentServiceCall() {
    this._serviceCallSnapShot = null;
    this.updateTruckStatus();
  }

  // Keeps our current service call information up to date
  public updateCurrentServiceCall(snapshot: any) {
    this._serviceCallSnapShot = snapshot;
    // After updating the snapshot we need to update our icon
    this.updateTruckStatus();
  }

  // Keeps our truck status information up to date
  public updateTruckStatusSnapShot(snapShot: any) {
    this._truckStatusSnapShot = snapShot;
    // After updating the snapshot we need to update our icon
    this.updateTruckStatus();
  }

  updateLocation(latlng: google.maps.LatLng) {
    this._currentLocation = latlng;
    if (this._marker) {
      this._marker.setPosition(latlng);
      this.updateWaitLocationLine();
    }
  }

  // use to set the current status of the truck, refer to notes for logic
  // NOTE: This needs to be called whenever the firebase data changes
  private updateTruckStatus(): void {
    // Check if we have all the info we need to update the status: a serviceCallSnapShot and truckStatusSnapShot
    if (this.truckStatusSnapShot) {
      if (this.hasServiceCallSnapShot === false
        || this.truckStatusSnapShot.truckActiveServiceCalls === null || this.currentServiceCallSnapshotStatus === 'CL') {
        if (this.truckStatusSnapShot.truckDriverStatus === 'IV') {
          this.currentStatus = TruckStatus.NotAssigned;
        } else {
          this.currentStatus = TruckStatus.DriverOutofVehicle;
        }

      } else if (this.currentServiceCallSnapshotStatus === 'OL' || this.currentServiceCallSnapshotStatus === 'TW') {
        this.currentStatus = TruckStatus.OnSiteTowing;
      } else {
        this.currentStatus = TruckStatus.AssignedToMember;
      }
      this.updateMarker(this.generateTruckInfoStr());
      this.updateWaitLocationLine();
    }
  }

  private generateTruckInfoStr(): string {
    return `
    <div style="font-size:  1.03em">
      <div style="text-align:center; margin-bottom: 10px;">
        <div style="color:#4563fd; font-size: 18px; font-weight:500">Truck Status</div>
        <div style="font-size:12px; font-weight: 400;color: #717171;">
          (Truck ID: ${this.truckStatusSnapShot.facilityId || `Unknown`}-${this.truckStatusSnapShot.truckId || `Unknown`})
        </div>
        <hr style="border: 0px; border-bottom: .1em solid #dedede;">
      </div>
      <div style="margin-bottom: 8px">
      <span style="font-weight: 500; color:#000000">Current status</span>:
        <span style="font-weight:bold; color:${TruckColors[this._currentStatus]}">${this._currentStatus || `None received`}
        </span>
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">Current service call:</span>
        <span style="color:#000000"> ${this.currentServiceCallSnapshotId || `None`}</span>
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">Truck driver status:</span>
        <span style="color:#000000"> ${this.truckStatusSnapShot.truckDriverStatus || `None received`}</span>
      </div>
      <div style="margin-bottom: 8px">
        <span style="font-weight: 500; color:#000000">Device Serial:</span>
        <span style="color:#000000"> ${this.truckStatusSnapShot.deviceSerial || `None received`}</span>
      </div>
    </div>
    `;
  }

  updateWaitLocationLine() {
    if (this.currentStatus === TruckStatus.NotAssigned
      && this.truckStatusSnapShot !== null
      && this.truckStatusSnapShot.waitLocation !== null
      && this._currentLocation) {

      // need to clear current line if it exists
      if (this._waitLocationPolyLine) {
        this._waitLocationPolyLine.setMap(null);
      }
      this._currentWaitLocation = new google.maps.LatLng(this.truckStatusSnapShot.waitLocation.location_1_lat,
        this.truckStatusSnapShot.waitLocation.location_1_long);

      const lineSymbol = {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: this._waitLocationArrowSize,
        fillColor: this._waitLocationColor,
        fillOpacity: 1,
      };

      // Create the polyline and add the symbol via the 'icons' property.
      this._waitLocationPolyLine = new google.maps.Polyline({
        path: [this._currentLocation, this._currentWaitLocation],
        strokeWeight: this._waitLocationArrowSize,
        strokeColor: this._waitLocationColor,
        icons: [{
          icon: lineSymbol,
          offset: '100%'
        }],
        map: this._currentMap
      });
    } else {
      if (this._waitLocationPolyLine) {
        this._waitLocationPolyLine.setMap(null);
      }
    }
  }

  setVisability(isVisable: boolean): void {
    this.isVisable = isVisable;

    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        if (this._waitLocationPolyLine) {
          this._waitLocationPolyLine.setMap(null);

        }
      } else {
        this._marker.setMap(this._currentMap);
        if (this._waitLocationPolyLine) {
          this._waitLocationPolyLine.setMap(this._currentMap);

        }
      }
    }

  }
}

const RotatedImage = function (options) {
  this.loaded = false;
  this.imgOnSiteTowing = new Image();
  this.imgAssignedToMember = new Image();
  this.imgNotAssigned = new Image();
  this.imgDriverOutofVehicle = new Image();
  this.imgOnSiteTowing.src = options.OnSiteTowingUrl;
  this.imgAssignedToMember.src = options.AssignedToMemberUrl;
  this.imgNotAssigned.src = options.NotAssignedUrl;
  this.imgDriverOutofVehicle.src = options.DriverOutofVehicleUrl;
  this.imgOnSiteTowingPromise = new Promise(resolve => {
    this.imgOnSiteTowing.onload = () => {
      resolve();
    };
  });
  this.imgAssignedToMemberPromise = new Promise(resolve => {
    this.imgAssignedToMember.onload = () => {
      resolve();
    };
  });
  this.imgNotAssignedPromise = new Promise(resolve => {
    this.imgNotAssigned.onload = () => {
      resolve();
    };
  });
  this.imgDriverOutofVehiclePromise = new Promise(resolve => {
    this.imgDriverOutofVehicle.onload = () => {
      resolve();
    };
  });
  this.loadedCallback = null;

  Promise.all(
    [this.imgOnSiteTowingPromise,
    this.imgAssignedToMemberPromise,
    this.imgNotAssignedPromise,
    this.imgDriverOutofVehiclePromise])
    .then(() => {
      this.loaded = true;
      this.canvas = document.createElement('canvas');
      this.scaleFactor = Math.sqrt(Math.pow(this.imgOnSiteTowing.width, 2) + Math.pow(this.imgOnSiteTowing.height, 2));
      this.width = this.scaleFactor;
      this.height = this.scaleFactor;
      this.canvas.width = this.width;
      this.canvas.height = this.height;

      if (this.loadedCallback != null) {
        this.loadedCallback();
      }
    }).catch((err) => {
      console.log(err);
    });
};

RotatedImage.prototype.setLoadedCallback = function (callback) {
  if (this.loaded) {
    callback();
    return;
  }
  this.loadedCallback = callback;
};
RotatedImage.prototype.rotate = function (degrees: number, truckStatus: TruckStatus) {
  if (!this.loaded) {
    return null;
  }

  const ctx = this.canvas.getContext('2d');
  ctx.setTransform(1, 0, 0, 1, 0, 0);
  ctx.clearRect(0, 0, this.width, this.height);

  // The rotation is not from 0, but from the last rotation that was applied
  ctx.translate(this.width / 2, this.height / 2);
  ctx.rotate(degreeToRadian(degrees));
  ctx.translate(-this.width / 2, -this.height / 2);
  switch (truckStatus) {
    case TruckStatus.OnSiteTowing:
      ctx.drawImage(this.imgOnSiteTowing, 0, 0, this.width, this.height);
      break;
    case TruckStatus.AssignedToMember:
      ctx.drawImage(this.imgAssignedToMember, 0, 0, this.width, this.height);
      break;
    case TruckStatus.NotAssigned:
      ctx.drawImage(this.imgNotAssigned, 0, 0, this.width, this.height);
      break;
    case TruckStatus.DriverOutofVehicle:
      ctx.drawImage(this.imgDriverOutofVehicle, 0, 0, this.width, this.height);
      break;
  }
  return this.canvas.toDataURL('image/svg');
};

function makeIcon(url, width) {
  return {
    url: url,
    scaledSize: new google.maps.Size(width, width),
    anchor: new google.maps.Point(width / 2, width / 2)
  };
}

function degreeToRadian(degree) { return degree * 0.0174532925; }


