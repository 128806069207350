import { TruckStatus } from 'src/models/map-marker/icon-status/truck-status';
import { FleetMenuService } from './../../services/fleet-menu/fleet-menu.service';
import { Component } from '@angular/core';

@Component({
  selector: 'app-fleet-menu',
  templateUrl: './fleet-menu.component.html',
  styleUrls: ['./fleet-menu.component.scss']
})
export class FleetMenuComponent {

  truckInfo: Array<object>;
  headerTitles = {
    status: 'Status',
    driverID: 'Driver ID',
    driverName: 'Driver Name',
    truckId: 'Truck ID',
    truckType: 'Truck Type',
    callId: 'Call ID',
    ETA: 'ETA',
    trackingUrl: 'Tracking Url'
  };
  showFacSelectMsg = true;
  hideScroll = true;

  constructor(private fleetMenuServ: FleetMenuService) {
    this.fleetMenuServ.truckInfo.subscribe((tInfoFleetMenu) => {
      this.handleTruckInfoUpdate(tInfoFleetMenu);
    });
  }

  private sortFleetArray(tInfo: Array<object>): Array<object> {
    if (tInfo.length < 1) {
      return [];
    }

    let orderedArray;
    const onSiteA = [];
    const enrouteA = [];
    const unassaignedA = [];
    const ovA = [];

    tInfo.forEach((trk) => {
      switch ((trk as any).status) {
        case TruckStatus.AssignedToMember:
          enrouteA.push(trk);
          break;
        case TruckStatus.DriverOutofVehicle:
          ovA.push(trk);
          break;
        case TruckStatus.NotAssigned:
          unassaignedA.push(trk);
          break;
        case TruckStatus.OnSiteTowing:
          onSiteA.push(trk);
          break;
        default:
          unassaignedA.push(trk);
          break;
      }
    });

    orderedArray = onSiteA.concat(enrouteA, unassaignedA, ovA);
    return orderedArray;
  }

  private handleTruckInfoUpdate(tInfo) {
    const orderedTInfo = this.sortFleetArray(tInfo);
    this.truckInfo = orderedTInfo;
    this.showFacSelectMsg = this.truckInfo.length < 1 ? true : false;
    this.hideScroll = this.truckInfo.length < 14 ? true : false;
  }



}
