import { HttpRequestsService } from '../httpRequests/http-requests.service';
import { GoogleMapService } from '../google-map/google-map.service';
declare var google: any;
import { Injectable } from '@angular/core';
import * as endPoints from '../httpRequests/apiEndpoints';
import { BehaviorSubject } from 'rxjs';
import { requestTypes } from '../httpRequests/http-requests.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SQLDataService {
  private _facilitySQLDataHash = {};
  private _facilitySQLDataArray = [];
  private _branchSQLDataArray = [];
  private _loadedSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _loadedStatic = false;


  constructor(private mapService: GoogleMapService,
    private httpServ: HttpRequestsService,
    private authServ: AuthService) {
  }

  private get loadedSub() {
    return this._loadedSub.asObservable();
  }

  public get facilitySQLDataArray() {
    return this._facilitySQLDataArray;
  }
  
  public get branchSQLDataArray() {
    return this._branchSQLDataArray;
  }

  public async facilitySQLDataByID(facID: string) {
    await this.SQLdataInitialLoadPromise();
    return this._facilitySQLDataHash[facID];
  }

  public SQLdataInitialLoadPromise(): Promise<void> {
    return new Promise((resolve) => {
      if (this._loadedStatic === true) {
        return resolve();
      }
      const sub = this.loadedSub.subscribe((loaded) => {
        if (loaded) {
          sub.unsubscribe();
          return resolve();
        }
      });
    });
  }

  async runInitialSQLdataJobs(): Promise<void> {
    // wait for SQL data and map apis to load
    await this.authServ.isSignedInPromise();
    await this.retrieveAllFacilitySQLData();
    await this.retrieveAllBranchSQLData();
    this._loadedSub.next(true);
    this._loadedStatic = true;
    return Promise.resolve();
  }

  private async retrieveAllFacilitySQLData(): Promise<void> {
    let facSQLinfo = null;
    try {
      facSQLinfo = await this.httpServ.sendRequest(requestTypes.get, endPoints.facLocEP);
    } catch (err) {
      console.log(err);
    }

    facSQLinfo.forEach((sqlData) => {
      const facAddressLine1 = `${sqlData.name}`;
      const facAddressLine2 = `${this.fixCase(sqlData.city)}, ${sqlData.state}, ${sqlData.zip_code}`;
      let latLng = null;
      if (sqlData.location != null) {
        latLng = this.extractLatLng(sqlData.location);
      }
      const facObj = {
        name: sqlData.name,
        facAdd1: facAddressLine1,
        facAdd2: facAddressLine2,
        facilityType: sqlData.club_fleet === true ? 'Club Fleet' : 'Service Provider',
        lat: latLng == null ? null : latLng[0],
        lng: latLng == null ? null : latLng[1],
        timezone: sqlData.timezone
      };
      this._facilitySQLDataHash[sqlData.facility_id] = facObj;
      this._facilitySQLDataArray.push(facObj);
    });
    return Promise.resolve();
  }

  private async retrieveAllBranchSQLData(): Promise<void> {
    let branchSQLinfo = null;
    try {
      branchSQLinfo = await this.httpServ.sendRequest(requestTypes.get, endPoints.branchEP);
    } catch (err) {
      console.log(err);
    }
    branchSQLinfo.forEach((sqlData) => {
      const facAddressLine1 = `${sqlData.street}`;
      const facAddressLine2 = `${this.fixCase(sqlData.city)}, ${sqlData.state}, ${sqlData.zip_code}`;
      let latLng = null;
      if (sqlData.location != null) {
        latLng = this.extractLatLng(sqlData.location);
      }
      const branchObj = {
        name: sqlData.location_name,
        locationID: sqlData.location_id,
        facAdd1: facAddressLine1,
        facAdd2: facAddressLine2,
        lat: latLng == null ? null : latLng[0],
        lng: latLng == null ? null : latLng[1],
      };
      this._branchSQLDataArray.push(branchObj);
    });
    return Promise.resolve();
  }

  extractLatLng(str) {
    const result = str.replace(/[A-Z()]/g, '').split(' ');
    return [result[1], result[0]];
  }


  fixCase(str) {
    if (str) {
      const lower = str.toLowerCase();
      const upper = lower.split(' ').map((el) => el.replace(/^\w/, c => c.toUpperCase())).join(' ');
      return upper;
    }

  }

  async getFacilityLocationById(facId: string): Promise<google.maps.LatLng> {
    if (!facId) {
      return null;
    }
    if (this._loadedStatic === false) {
      await this.SQLdataInitialLoadPromise();
    }
    if (facId in this._facilitySQLDataHash) {
      await this.mapService.getMapRefLoadPromise();
      const location = new google.maps.LatLng(this._facilitySQLDataHash[facId].lat, this._facilitySQLDataHash[facId].lng);
      return location;
    } else {
      console.log('do not have location for this facility');
      return null;
    }
  }

  async getPredictionData(dateFilter: string, facID: string): Promise<Array<Object>> {
    const predictions = await this.httpServ.sendRequest(requestTypes.get, endPoints.predictionEP(facID, dateFilter));
    const grids = await this.httpServ.sendRequest(requestTypes.get, endPoints.sectionsEP(facID));
    const results = this.joinGridsAndPredictions(predictions, grids);
    return Promise.resolve(results);
  }

  joinGridsAndPredictions(predictions, grids): Array<Object> {
    const allGridHash = {};
    grids.forEach(el => {
      const predOb = { ...el, light_calls: 0, tow_calls: 0 };
      allGridHash[el.id] = predOb;
    });
    predictions.forEach(el => {
      allGridHash[el.section_id].light_calls = el.light_calls;
      allGridHash[el.section_id].tow_calls = el.tow_calls;
    });
    return Object.values(allGridHash);
  }

  async getWaitLocationsByFacId(facID: string) {
    const result = await this.httpServ.sendRequest(requestTypes.get, endPoints.waitLocationsEP(facID));
    let allLocArray = [];
    if (result) {
      result.forEach((dataRow) => {
        allLocArray.push(this.extractWaitLocations(dataRow));
      });
      allLocArray = this.getWLUnique(allLocArray);
    }
    return allLocArray;
  }

  // address, lat, lng, name
  extractWaitLocations(wlData: any): object {

    const infoObj = { facID: wlData.facility_id };
    const latLng = this.extractLatLng(wlData.location);
    infoObj['address'] = wlData.address;
    infoObj['lat'] = latLng[0];
    infoObj['lng'] = latLng[1];
    infoObj['name'] = wlData.name;

    return infoObj;
  }

  // checks if the wait location object is the same as one that already exists in the array
  getWLUnique(wlData: Array<object>): Array<object> {
    const lookupHash = {};
    wlData.forEach((el) => {
      lookupHash[`${el['lat']}_${el['lng']}`] = el;
    });
    return Object.keys(lookupHash).map((k) => lookupHash[k]);
  }

  public async getCarvingShapeFiles(facID: string): Promise<object> {
    const data = { facId: facID };
    const results = await this.httpServ.sendRequest(requestTypes.get, endPoints.shapeFilesEP(facID), data);
    return results;
  }



}
