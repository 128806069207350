import { GenericIcon } from './generic-icon.model';

export class BranchIcon extends GenericIcon {
  private _branchIconSize = 28;
  private _zIndex = 30;
  iconFile= `${this.iconBaseDir}/viewLayer-icons/branch-icon.svg`;
  branchName: string;
  locationID: string;
  branchAddr1: string;
  branchAddr2: string;
  currentLocation: google.maps.LatLng;

  constructor(currMap: google.maps.Map,
    branchName: string,
    locationID: string,
    branchAddr1: string,
    branchAddr2: string,
    currLoc: google.maps.LatLng) {
    super(currMap);
    this.branchName = branchName;
    this.locationID = locationID;
    this.branchAddr1 = branchAddr1;
    this.branchAddr2 = branchAddr2;
    this.currentLocation = currLoc;
    this.createMarker();
  }

  createMarker() {
    const newIcon = {
      url: this.iconFile,
      scaledSize: new google.maps.Size(this._branchIconSize, this._branchIconSize),
      zIndex: this._zIndex
    };
    const markerOptions = { position: this.currentLocation };
    markerOptions['icon'] = newIcon;
    this._marker = new google.maps.Marker(markerOptions);
    this.setMarkerInfoWindow();
    this.updateInfoWindow(this.generateBranchInfoStr());
    // fix for info window flicker
    this._currentInfoWindow.set('pixelOffset', new google.maps.Size(0, -3));
  }

  generateBranchInfoStr(): string {
    return `
    <div style="text-align:left; padding: 5px;">
      <div style="text-align: left;
      color: navy;
      font-size: 14px;
      margin-bottom: 1px;
      font-weight: 500;">${this.branchName} <span style="color: grey; font-size: 14px">(ID: ${this.locationID})</span></div>
      <div>${this.branchAddr1}</div>
      <div>${this.branchAddr2}</div>
    </div>
    `;
  }
  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }
}

