declare let google: any;

export class GenericIcon {
  _marker: google.maps.Marker = null;
  _iconBaseDir = 'assets/imgs';
  // _iconSize = 28;
  _iconSize = null;
  _currentMap: google.maps.Map;
  _currentLocation = null;
  _currentInfoWindow = null;
  isVisable = true;
  private _infoWindowHoverOpen = false;

  constructor(currMap: google.maps.Map) {
    this._currentMap = currMap;
  }

  get iconBaseDir() {
    return this._iconBaseDir;
  }

  get iconSize() {
    return this._iconSize;
  }

  hasMarker(): boolean {
    return this._marker !== null;
  }

  updateInfoWindow(infoContent: string) {
    this.updateInfoWindowContent(infoContent);
  }

  setMarkerInfoWindow() {
    if (this._currentInfoWindow === null) {
      this._currentInfoWindow = new google.maps.InfoWindow({
        content: ''
      });
      this._marker.addListener('click', () => {
        this._infoWindowHoverOpen = false;
        this.openInfoWindow();
      });
      this._marker.addListener('mouseover', () => {
        this._infoWindowHoverOpen = true;
        this.openInfoWindow();
      });
      this._marker.addListener('mouseout', () => {
        if (this._infoWindowHoverOpen) {
          this.closeInfoWindow();
          this._infoWindowHoverOpen = false;
        }
      });
    }
  }

  private openInfoWindow() {
    this._currentInfoWindow.open(this._currentMap, this._marker);
  }
  private closeInfoWindow() {
    this._currentInfoWindow.close(this._currentMap, this._marker);
  }


  private updateInfoWindowContent(content: string) {
    this._currentInfoWindow.setContent(content);
  }


}
