import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { appConfig } from 'src/app/app-config-defaults';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceDefaultServiceService {
  private _defaultFacility: string; // first facility in array is the most recent
  private _lngCookieExpire = 10 * 365; // for long cookies
  private _cookieDelimiter = '|';
  constructor(private cookieServ: CookieService) {
    // cookieServ.deleteAll();
  }

  get defaultFacility(): string {
    this._defaultFacility = this.loadFacilityCookie();
    return this._defaultFacility;
  }


  async getDefaultMapLocation(): Promise<google.maps.LatLng> {
    let result: google.maps.LatLng;
    result = new google.maps.LatLng(appConfig.startLatLng[0], appConfig.startLatLng[1]);
    return Promise.resolve(result);
  }

  get defaultMapZoom(): number {
    return appConfig.startZoom;
  }

  private loadFacilityCookie(): string {
    // Note: load cookies for dev and prod seperately
    let result: string;
    if (environment.name !== 'dev') {
      result = this.cookieServ.get(CookieTypes.ProdDefaultFacility);
    } else {
      result = this.cookieServ.get(CookieTypes.DevDefaultFacility);
    }

    return result;
  }

  public storeFacilityCookie(facID: string) {

    if (environment.name !== 'dev') {
      this.cookieServ.set(CookieTypes.ProdDefaultFacility, facID, this._lngCookieExpire);
    } else {
      this.cookieServ.set(CookieTypes.DevDefaultFacility, facID, this._lngCookieExpire);
    }
  }

  loadAutoPredRefreshCookie(): string {
    return this.cookieServ.get(CookieTypes.AutoPredRefresh);
  }

  storeAutoPredRefreshCookie(isEnabled) {
    this.cookieServ.set(CookieTypes.AutoPredRefresh, isEnabled, this._lngCookieExpire);
  }

}

export const enum CookieTypes {
  ProdDefaultFacility = 'ProdDefaultFacility',
  DevDefaultFacility = 'DevDefaultFacility',
  AutoPredRefresh = 'AutoPredRefresh'
}

