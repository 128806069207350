import { GenericIcon } from './generic-icon.model';
import * as AQIcalc from 'src/app/helper-funcs/AQIcalculator';

export class AirQualityIcon extends GenericIcon {
  private _airQualityIconSize = 33;
  private _airQualityFontSize = 13;
  // private _zIndex = 13;
  public id : string;
  data = null;
  iconFile_stem = `${this.iconBaseDir}/airQuality_`;
  airQualityIndex: number; 
  location: google.maps.LatLng;

  constructor(currMap: google.maps.Map, id: string, data: any) {
    super(currMap);
    this.id = id;
    this.data= data;
    // console.log(data)
    if(data.stats && data.stats.v1 && Number.isNaN(data.stats.v1) === false){
      this.airQualityIndex = AQIcalc.aqiFromPM(data.stats.v1); //note v1 is the 10min interval
      this.location = new google.maps.LatLng(data.location.latitude, data.location.longitude);
      this.createMarker();
    }
    
  }

  createMarker() {

    let titleData = `Label: ${this.data.label}`;
    let labelColor = this.airQualityIndex < 151 ? 'black' : 'white';
    const labelData = {text: this.airQualityIndex.toString(), color: labelColor, fontSize: this._airQualityFontSize.toString()+'px'};
    const iconFile = `${this.iconFile_stem}${this.getAQIRange(this.airQualityIndex)}.svg`
    const newIcon = {
      url: iconFile,
      scaledSize: new google.maps.Size(this._airQualityIconSize, this._airQualityIconSize)
    };
    const markerOptions = {
      position: this.location,
      icon: newIcon,
      label: labelData,
      map: this._currentMap,
      // title: titleData,
      zIndex: Math.floor(Math.random() * 1000)
    };
    this._marker = new google.maps.Marker(markerOptions);
    this.setMarkerInfoWindow();
    this.updateInfoWindow(this.generateInfoStr());
    // fix for info window flicker
    this._currentInfoWindow.set('pixelOffset', new google.maps.Size(0, -3));
  }

  generateInfoStr(): string {
    return `
    <div style="text-align:left; padding: 5px; font-size: 14px">
      <div style="text-align: center;
        color: black;
        font-size: 16px;
        margin-bottom: 1px;
        font-weight: 600;">
        <span style="font-weight: 400">10 Minute AVG US EPA PM2.5 AQI: </span><span style="font-size: 20px">${this.airQualityIndex}</span>
        <div><span style="font-weight: 400">Air Quality: </span><span style="font-weight: 600">${AQIcalc.getAQIDescription(this.airQualityIndex)}</span></div>
      </div> 
      <div style="margin-top:5px">${AQIcalc.getAQIMessage(this.airQualityIndex)}</div>
    </div>
    `;
  }

  getAQIRange(AQI){
    if(AQI < 51){
      return '0_50'
    }
    if(AQI < 101){
      return '51_100'
    }
    if(AQI < 151){
      return '101_150'
    }
    if(AQI < 201){
      return '151_200'
    }
    if(AQI < 301){
      return '201_300'
    }
    return '301_500'
  }

  setVisability(isVisable: boolean): void {
    if (this._marker) {
      if (isVisable === false) {
        this._marker.setMap(null);
        this.isVisable = false;
      } else {
        this._marker.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }

}

