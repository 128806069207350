export const facLocEP =`/facilities`;
export const branchEP  = `/branches`;

export const waitLocationsEP = (facID) => {
  return `/facilities/${facID}/wait_locations?backup_level=0`;
};

export const shapeFilesEP = (facID) => {
  return `/facilities/${facID}/boundaries?backup_level=0`;
};

// date format: '2019-12-20T13' (UTC time, T = hour)
export const predictionEP = (facID, date) => {
  return `/facilities/${facID}/predictions/${date}?backup_level=0`;
};

export const sectionsEP = (facID) => {
  return `/facilities/${facID}/sections?backup_level=0`;
};
