import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconLayersService {

  private _visableIconsInternal = {};
  private _visableIconsObs: BehaviorSubject<Object> = new BehaviorSubject<Object>({});

  get visableIcons(): Observable<Object> {
    return this._visableIconsObs.asObservable();
  }

  public checkIconIsVisable(iType: IconLayerTypes): boolean {
    if (iType in this._visableIconsInternal) {
      return true;
    } else {
      return false;
    }
  }

  constructor() { }

  /**
   * Change the state of showing/hiding icons
   * @param iconType Type of Icon (fleet, service provider, etc)
   * @param isVisable either true or false
   */
  iconVisabilityStateChange(iconType: IconLayerTypes, isVisable: boolean) {
    switch (isVisable) {
      case false:
        if (iconType in this._visableIconsInternal) {
          delete this._visableIconsInternal[iconType];
        }
        break;
      case true:
        this._visableIconsInternal[iconType] = true;
        break;
    }
    this._visableIconsObs.next(this._visableIconsInternal);
  }
}

export enum IconLayerTypes {
  Branch = 'BRANCH',
  ServiceFacility = 'SERVICE_PROVIDER',
  FleetFacility = 'FLEET',
  WaitLocations = 'WAIT_LOCATIONS',
  ShowOV = 'SHOW_OV',
  Boundries = 'BOUNDRIES',
  Fires = 'FIRES',
  AirQuality = 'AIR_QUALITY',
  Counties = 'COUNTIES',
  ShapeFileTow = 'SHAPEFILE_TOW',
  ShapeFileLight = 'SHAPEFILE_LIGHT',
  ShapeFileBattery = 'SHAPEFILE_BATTERY',
  ClosedCalls = 'CLOSED_CALLS'
}
