
export class CountyGrid {
  isVisable: boolean;
  private currentMap: google.maps.Map;
  private labelSize = 160;
  private labelUrl = 'assets/imgs/county-label.svg';
  private labelFontSize = '18px';
  private coordinates: any;
  private labelMarker: google.maps.Marker;
  private countyPoly: google.maps.Polygon;
  private polyFillColor = '#4286f4';
  private polyStrokeColor = '#0964f7';
  private polyDefaultOpacity = 0.2;
  private polyHoverOpacity = 0.7;
  private countyName: string;
  private labelZindex = 20;

  private labelIcon = {
    url: this.labelUrl,
    scaledSize: new google.maps.Size(this.labelSize, this.labelSize),
    anchor: new google.maps.Point(this.labelSize / 2, this.labelSize / 2)
  };


  constructor(currMap: google.maps.Map, coordinates: Array<Object>, name: string) {
    this.currentMap = currMap;
    this.coordinates = coordinates;
    this.countyName = name;
    this.createCounty();
  }

  createCounty() {
    this.countyPoly = new google.maps.Polygon({
      paths: this.coordinates,
      strokeColor: this.polyStrokeColor,
      strokeOpacity: 0.7,
      strokeWeight: 2,
      fillColor: this.polyFillColor,
      fillOpacity: this.polyDefaultOpacity,
      map: null
    });

    this.labelMarker = new google.maps.Marker({
      position: this.findCenterPoly(this.coordinates),
      map: null,
      zIndex: this.labelZindex,
      icon: this.labelIcon,
      label: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: this.labelFontSize,
        text: this.countyName,
      }
    });

  }

  addListeners() {
    google.maps.event.addListener(this.countyPoly, 'mouseover', () => {
      this.labelMarker.setMap(this.currentMap);
      this.countyPoly.setOptions({ 'fillOpacity': this.polyHoverOpacity });
    });

    google.maps.event.addListener(this.countyPoly, 'mouseout', () => {
      this.labelMarker.setMap(null);
      this.countyPoly.setOptions({ 'fillOpacity': this.polyDefaultOpacity });
    });

  }

  removeListeners() {
    google.maps.event.clearListeners(this.countyPoly, 'mouseover');
    google.maps.event.clearListeners(this.countyPoly, 'mouseout');
  }

  setVisability(isVisable: boolean): void {
    switch (isVisable) {
      case true:
        this.countyPoly.setMap(this.currentMap);
        this.addListeners();
        break;
      case false:
        this.countyPoly.setMap(null);
        this.removeListeners();
    }
  }

  findCenterPoly(arr: Array<Object>) {
    let x1 = arr[0]['lat'];
    let x2 = arr[0]['lat'];
    let y1 = arr[0]['lng'];
    let y2 = arr[0]['lng'];

    arr.forEach((el) => {
      if (el['lat'] < x1) {
        x1 = el['lat'];
      }
      if (el['lat'] > x2) {
        x2 = el['lat'];
      }
      if (el['lng'] < y1) {
        y1 = el['lng'];
      }
      if (el['lng'] > y2) {
        y2 = el['lng'];
      }
    });

    const centerX = x1 + ((x2 - x1) / 2);
    const centerY = y1 + ((y2 - y1) / 2);
    return { lat: centerX, lng: centerY };
  }

}

