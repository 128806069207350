import { SettingsPanelAnimationService } from './../../services/settings-panel-animation/settings-panel-animation.service';
import { Component, OnInit, Input } from '@angular/core';
import { state, transition, animate, trigger, style } from '@angular/animations';
import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';

@Component({
  selector: 'app-settings-panel',
  templateUrl: './settings-panel.component.html',
  styleUrls: ['./settings-panel.component.scss'],
  animations: [
    trigger('openClose', [

      state('open', style(
        { opacity: '1', height: 'inherit', pointerEvents: 'auto' }
      )),
      state('closed', style(
        { opacity: '0', height: 'inherit', pointerEvents: 'none' }
      )),

      transition('open => closed', [
        animate('50ms')
      ]),
      transition('closed => open', [
        animate('100ms ease-out')
      ])
    ])
  ]
})
export class SettingsPanelComponent implements OnInit {
  @Input() filterType: SettingsPanelState;
  isOpen = true;

  toggle() {
    this.isOpen = !this.isOpen;
  }

  constructor(private settingsAni: SettingsPanelAnimationService) { }

  ngOnInit() {
    this.settingsAni.settingsPanelStateListener.subscribe((panelState) => {
      if (this.filterType === panelState) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    });
  }

  toggleOpen(isOpened: boolean) {
    this.isOpen = isOpened;
  }

}
