/// <reference types="@types/googlemaps" />
declare const google: any;

export let appConfig = {
  // Arizona
  // startLatLng: [33.509264, -112.03841],
  startLatLng: [36.63429799275694, -116.83282600449444],
  startZoom: 7, // lower is more zoomed out
  appName: 'com.goaaa.kassandra'
  // serviceCallTimeWindowMax: 1,
  // this is the distance in meters from the center of the map
  // 100 miles = 160,934 meters
  // maxServiceCallDist: 160934
};
