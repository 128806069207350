import { SettingsPanelAnimationService } from './../../services/settings-panel-animation/settings-panel-animation.service';
/// <reference types="@types/googlemaps" />
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { GoogleMapService } from 'src/services/google-map/google-map.service';
import { GoogleMapsApiLoaderService } from 'src/services/google-maps-api-loader/google-maps-api-loader.service';
import { SettingsPanelState } from 'src/services/settings-panel-animation/settings-panel-states';
declare let google: any;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

  @ViewChild('mapContainer') mapContainer: ElementRef;
  map: google.maps.Map = null;

  constructor(private mapService: GoogleMapService,
    private mapsApiLoader: GoogleMapsApiLoaderService, private settingsAni: SettingsPanelAnimationService) { }

  ngOnInit() {
    if (this.map === null) {
      const sub = this.mapsApiLoader.loaded.subscribe((loaded) => {
        if (loaded) {
          this.mapService.createMap(this.mapContainer.nativeElement, null, null)
            .then((map) => {
              this.map = map;
            });
          if (sub) {
            sub.unsubscribe();
          }
        }
      });
    }
  }
  handleClick() {
    this.settingsAni.updateSettingsPanelState(SettingsPanelState.Closed);
  }


}
