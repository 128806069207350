
export class PredictionGrid {
  bounds: any;
  private _googleRect: any;
  private _googleLine1: any;
  private _googleLine2: any;
  private _googleLine3: any;
  private _googleLine4: any;
  isVisable: boolean;
  _currentMap: google.maps.Map;
  gridType: GridTypes;
  private _defaultFillOpacity = 0.09;
  private _hoverFillOpacity = 0.4;

  // viewLayerRect is the dashed outline grids
  viewLayerRectSettings = {
    strokeColor: 'rgba(138,138,138,0.7)',
    strokeOpacity: 1,
    strokeWeight: 1.4,
    fillOpacity: 0,
    zIndex: 1
  };

  predRectSettings = {
    strokeColor: '#4286f4',
    strokeOpacity: 0.85,
    strokeWeight: 1.6,
    fillColor: '#4286f4',
    fillOpacity: this._defaultFillOpacity,
    zIndex: 5
  };

  constructor(currMap: google.maps.Map, coordinates: any, gridType: GridTypes) {
    this._currentMap = currMap;
    this.bounds = {
      north: coordinates.minLat,
      south: coordinates.maxLat,
      east: coordinates.maxLng,
      west: coordinates.minLng
    };
    this.gridType = gridType;
    if (gridType === GridTypes.viewLayerGrid) {
      this.isVisable = false;
      this.createDashedRectangle(this.viewLayerRectSettings);
    } else {
      this.isVisable = true;
      this.createRectangle(this.predRectSettings);
      this.setVisability(true);
    }

  }

  createRectangle(rectOptions: Object) {
    rectOptions['bounds'] = this.bounds;
    this._googleRect = new google.maps.Rectangle(rectOptions);
  }

  createDashedRectangle(rectOptions: Object) {
    const lineRepeat = '10px';
    const lineOffset = '0';

    const rectPath1 = [
      new google.maps.LatLng(this.bounds.north, this.bounds.west),
      new google.maps.LatLng(this.bounds.north, this.bounds.east)
    ];
    const rectPath2 = [
      new google.maps.LatLng(this.bounds.north, this.bounds.west),
      new google.maps.LatLng(this.bounds.south, this.bounds.west)
    ];
    const rectPath3 = [
      new google.maps.LatLng(this.bounds.south, this.bounds.west),
      new google.maps.LatLng(this.bounds.south, this.bounds.east)
    ];
    const rectPath4 = [
      new google.maps.LatLng(this.bounds.north, this.bounds.east),
      new google.maps.LatLng(this.bounds.south, this.bounds.east)
    ];
    const lineSymbol = {
      path: 'M 0,-1 0,1',
      strokeOpacity: rectOptions['strokeOpacity'],
      scale: rectOptions['strokeWeight']
    };

    this._googleLine1 = new google.maps.Polyline({
      strokeColor: rectOptions['strokeColor'],
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: lineOffset,
        repeat: lineRepeat
      }],
      path: rectPath1,
    });
    this._googleLine2 = new google.maps.Polyline({
      strokeColor: rectOptions['strokeColor'],
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: lineOffset,
        repeat: lineRepeat
      }],
      path: rectPath2,
    });
    this._googleLine3 = new google.maps.Polyline({
      strokeColor: rectOptions['strokeColor'],
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: lineOffset,
        repeat: lineRepeat
      }],
      path: rectPath3,
    });
    this._googleLine4 = new google.maps.Polyline({
      strokeColor: rectOptions['strokeColor'],
      strokeOpacity: 0,
      icons: [{
        icon: lineSymbol,
        offset: lineOffset,
        repeat: lineRepeat
      }],
      path: rectPath4,
    });

  }

  setVisability(isVisable: boolean): void {
    switch (this.gridType) {
      case GridTypes.predGrid:
        this.setDefaultRectVisability(isVisable);
        break;
      case GridTypes.viewLayerGrid:
        this.setLineRectVisability(isVisable);
        break;
    }
  }

  setDefaultRectVisability(isVisable) {
    if (this._googleRect) {
      if (isVisable === false) {
        this._googleRect.setMap(null);
        this.isVisable = false;
        // this.removeListeners();
      } else {
        this._googleRect.setMap(this._currentMap);
        this.isVisable = true;
        // this.addListeners();
      }
    }
  }

  setLineRectVisability(isVisable) {
    if (this._googleLine1 && this._googleLine2 && this._googleLine3 && this._googleLine4) {
      if (isVisable === false) {
        this._googleLine1.setMap(null);
        this._googleLine2.setMap(null);
        this._googleLine3.setMap(null);
        this._googleLine4.setMap(null);
        this.isVisable = false;
      } else {
        this._googleLine1.setMap(this._currentMap);
        this._googleLine2.setMap(this._currentMap);
        this._googleLine3.setMap(this._currentMap);
        this._googleLine4.setMap(this._currentMap);
        this.isVisable = true;
      }
    }
  }

  addListeners() {
    if (this.gridType === GridTypes.predGrid) {
      google.maps.event.addListener(this._googleRect, 'mouseover', () => {
        this._googleRect.setOptions({
          fillOpacity: this._hoverFillOpacity
        });
      });
      google.maps.event.addListener(this._googleRect, 'mouseout', () => {
        this._googleRect.setOptions({
          fillOpacity: this._defaultFillOpacity
        });
      });
    }
  }

  removeListeners() {
    if (this.gridType === GridTypes.predGrid) {
      google.maps.event.clearListeners(this._googleRect, 'mouseover');
      google.maps.event.clearListeners(this._googleRect, 'mouseout');
    }
  }
}

export enum GridTypes {
  predGrid = 'Prediction_Grid',
  viewLayerGrid = 'ViewLayer_Grid',
}

